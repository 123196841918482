import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import PropTypes from "prop-types"

export const EvidenceDialog = ({ open, handleClose, handleChange, description, handleAdd }) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Evidencia</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Agrega una descripción de la evidencia
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='description'
            name='description'
            label='Descripción'
            type='text'
            fullWidth
            variant='standard'
            value={description}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAdd}>Agregar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

EvidenceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
}
