import { format } from "date-fns"
import { es } from "date-fns/locale"

// Create function to mask string by use credit card number
export const maskString = (str, mask) => {
  let result = ""
  for (let i = 0; i < str.length; i++) {
    result += mask[i] === "#" ? str[i] : mask[i]
  }
  return result
}

// Create function to handle format date
export const formatDate = (date) => {
  if (date) {
    return format(new Date(date), "EEEE dd MMMM yyyy", { locale: es })
  }

  return "Fecha invalida"
}

// check days to expiration
export const checkDaysToExpiration = (date) => {
  if (date) {
    const today = new Date()
    const expirationDate = new Date(date)
    const diff = expirationDate.getTime() - today.getTime()
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24))
    return { valid: days > 0, days }
  }
  return { valid: false, days: 0 }
}

// check if date is not more than 3 months
export const checkDateNotMoreThreeMonths = (date) => {
  if (date) {
    const today = new Date()
    const expirationDate = new Date(date)
    const diff = today.getTime() - expirationDate.getTime()
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24))
    return { valid: days < 90, days }
  }
  return { valid: false, days: 0 }
}

//encode Uint8Array -> into iso-8859-1 encoded string (latin1-string)
export const Uint8ToLatin1Str = (Uint8Arr) => {
  var iso_8859_1_string = ""
  for (var i = 0; i < Uint8Arr.length; i++) {
    iso_8859_1_string += String.fromCharCode(Uint8Arr[i])
  }
  return iso_8859_1_string
}

//get the last 10 years in array
export const getLast10Years = () => {
  const years = []
  const currentYear = new Date().getFullYear() + 1
  for (let i = currentYear; i > currentYear - 10; i--) {
    years.push(`${i}`)
  }
  return years
}

export const checkIfNumber = (event) => {
  const regex = new RegExp(
    /(^[0-9]$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
  )

  return !event.key.match(regex) && event.preventDefault()
}

export const dateParse = (date) => {
  // get length of date
  if (date.length < 19) {
    return date + ":00Z"
  }

  return date + "Z"
}

export const getNowLocalDate = () => {
  const options = {
    timeZone: "America/Hermosillo",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZoneName: "short",
  }

  return new Date(Date().toLocaleString("es-MX", options))
}
