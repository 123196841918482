import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'evidences',
  initialState: {
    list: [],
  },
  reducers: {
    setEvidence: (state, { payload }) => {
      state.list = state.list.map((evidence) => {
        if (evidence.key === payload.key) {
          return payload;
        }
        return evidence;
      });
    },
    setEvidenceImage: (state, { payload }) => {
      state.list = state.list.map((evidence) => {
        if (evidence.key === payload.key) {
          return { ...evidence, img: payload.img };
        }
        return evidence;
      });
    },
    addEvidence: (state, { payload }) => {
      state.list.push(payload);
    },
    removeEvidence: (state, { payload }) => {
      state.list = state.list.filter((evidence) => evidence.id !== payload);
    },
  },
});

export const { setEvidence, setEvidenceImage, addEvidence, removeEvidence } = slice.actions;
export default slice.reducer;

export const selectEvidences = (state) => state.evidences.list;
