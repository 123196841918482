import { Outlet } from "react-router-dom"
import AppFrame from "../../components/AppFrame"

const Users = () => {
  return (
    <AppFrame title='Usuarios'>
      <Outlet />
    </AppFrame>
  )
}

export default Users
