import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material"
import { checkboxes } from "../../constant/vehicleData"
import PropTypes from "prop-types"

export const ViewCheckboxes = ({ properties }) => {
  return (
    <Grid container flexDirection='column' mt={2}>
      <Grid item>
        <Typography variant='h6' gutterBottom>
          Tecnologías
        </Typography>
      </Grid>
      {checkboxes.map((checkbox) => (
        <Grid item key={checkbox.name}>
          <FormControlLabel
            label={checkbox.label}
            control={
              <Checkbox
                name={checkbox.name}
                checked={properties[checkbox.name]}
                inputProps={{ "aria-label": checkbox.label, disabled: true }}
              />
            }
          />
        </Grid>
      ))}
    </Grid>
  )
}

ViewCheckboxes.propTypes = {
  properties: PropTypes.object.isRequired,
}
