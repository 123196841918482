import { Button, Grid, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import CircularProgress from "@mui/material/CircularProgress"
import TakePicture from "../TakePicture/TakePicture"
import SelectRegions from "../SelectRegions/SelectRegions"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useState } from "react"
import { addError } from "../../redux/slices/errors"
import { useRegisterUserMutation } from "../../redux/apis/users"
import { areErrorsInputs } from "../../utils/validations"

const FromTenant = ({ handleTypeUser }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // TODO: convert variables to objects to add error flags for showing errors in inputs
  // TODO: add show name to objects and use it in the form and error messages
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    street: "",
    number: "",
    region: "",
    zip: "",
    ine: "",
    ine_expiration: "",
    license: "",
    license_expiration: "",
    proof_of_address: "",
    proof_of_address_date: "",
    account_state: "",
    account_state_date: "",
  })

  const [RegisterUser, { isLoading: isLoadingRegister }] =
    useRegisterUserMutation()

  const handleRegisterUser = () => {
    const { op, errors } = areErrorsInputs(values)

    if (op) {
      return dispatch(addError(errors))
    }

    RegisterUser({
      type: "tenant",
      user: {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        password: values.password,
      },
      address: {
        street: values.street,
        number: values.number,
        region: { id: values.region },
        zip: values.zip,
      },
      documents: [
        {
          name: "ine",
          img: values.ine,
          expires_at: new Date(values.ine_expiration),
          type: "IDENTIFICATION",
        },
        {
          name: "license",
          img: values.license,
          expires_at: new Date(values.license_expiration),
          type: "LICENSE_DRIVER",
        },
        {
          name: "proof_of_address",
          img: values.proof_of_address,
          expires_at: new Date(values.proof_of_address_date),
          type: "PROOF_OF_ADDRESS",
        },
        {
          name: "account_state",
          img: values.account_state,
          expires_at: new Date(values.account_state_date),
          type: "ACCOUNT_STATE",
        },
      ],
    })
      .then((res) => {
        const { error, data } = res
        if (error) {
          console.log("then -->", error)
          dispatch(
            addError({
              kind: "fetch_error",
              severity: "error",
              message: error.data,
            })
          )
        }

        if (data) {
          navigate("/sign-in")
        }
      })
      .catch((err) => {
        console.log("catch -->", err)
        dispatch(
          addError({
            kind: "fetch_error",
            severity: "error",
            message:
              "Hubo un problema al tratar de registrarse por favor inténtelo más tarde",
          })
        )
      })
  }

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value })
  }
  const handleTakePicture = ({ name, base64 }) => {
    setValues({ ...values, [name]: base64 })
  }

  return (
    <Box component='form'>
      <Typography variant='h5' component='h2' gutterBottom sx={{ mb: 2 }}>
        Registra te como nuevo cliente
      </Typography>
      {/* ===== START Names ===== */}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name='first_name'
            label='Nombre'
            variant='filled'
            onChange={handleChange("first_name")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name='last_name'
            label='Apellido'
            variant='filled'
            onChange={handleChange("last_name")}
          />
        </Grid>
        {/* TODO: Fix maxLength to 10 */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            name='phone'
            label='Numero de celular'
            variant='filled'
            type='number'
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 10,
            }}
            value={values.phone}
            onChange={handleChange("phone")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name='email'
            label='Correo'
            variant='filled'
            type='email'
            onChange={handleChange("email")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name='password'
            label='Contraseña'
            variant='filled'
            type='password'
            onChange={handleChange("password")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name='confirm'
            label='Confirmar Contraseña'
            variant='filled'
            type='password'
            value={values.confirm}
            onChange={handleChange("confirm")}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectRegions region={values.region} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name='street'
            label='Calle'
            variant='filled'
            value={values.street}
            onChange={handleChange("street")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name='number'
            label='Numero exterior'
            variant='filled'
            value={values.number}
            onChange={handleChange("number")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name='zip'
            label='Código postal'
            variant='filled'
            value={values.zip}
            onChange={handleChange("zip")}
          />
        </Grid>
      </Grid>
      {/* ===== END Names ===== */}
      {/* ===== START ID ===== */}
      <Grid container spacing={1}>
        <Grid item>
          <TakePicture
            name={"ine"}
            showName={"Identificación"}
            cameraWidth={640 / 2}
            cameraHeight={480 / 2}
            handleTakePicture={handleTakePicture}
          />
          <TextField
            fullWidth
            sx={{ marginTop: "1rem" }}
            name='ine_expiration'
            label='Fecha de expiración'
            type='date'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange("ine_expiration")}
          />
        </Grid>
        <Grid item>
          <TakePicture
            name={"license"}
            showName={"Licencia de conducir"}
            cameraWidth={640 / 2}
            cameraHeight={480 / 2}
            handleTakePicture={handleTakePicture}
          />
          <TextField
            fullWidth
            sx={{ marginTop: "1rem" }}
            name='license_expiration'
            label='Fecha de expiración'
            type='date'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange("license_expiration")}
          />
        </Grid>
        <Grid item>
          <TakePicture
            name={"proof_of_address"}
            showName={"Comprobante de domicilio"}
            cameraWidth={640 / 2}
            cameraHeight={480 / 2}
            handleTakePicture={handleTakePicture}
          />
          <TextField
            fullWidth
            sx={{ marginTop: "1rem" }}
            name='proof_of_address_date'
            label='Fecha del comprobante'
            type='date'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange("proof_of_address_date")}
          />
          <Typography sx={{ marginTop: "1rem" }} variant='body2'>
            {" "}
            Menor a tres 3 meses de antigüedad
          </Typography>
        </Grid>
        <Grid item>
          <TakePicture
            name={"account_state"}
            showName={"Estado de cuenta"}
            cameraWidth={640 / 2}
            cameraHeight={480 / 2}
            handleTakePicture={handleTakePicture}
          />
          <TextField
            fullWidth
            sx={{ marginTop: "1rem" }}
            name='account_state_date'
            label='Fecha del estado de cuenta'
            type='date'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange("account_state_date")}
          />
          <Typography sx={{ marginTop: "1rem" }} variant='body2'>
            {" "}
            Menor a tres 3 meses de antigüedad
          </Typography>
        </Grid>
      </Grid>
      {/* ===== END ID ===== */}
      <Grid item container spacing={1} mt={2}>
        <Grid item xs={12} md>
          <Button
            fullWidth
            variant='contained'
            color='error'
            onClick={() => handleTypeUser("")}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={12} md>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            onClick={handleRegisterUser}
          >
            {!isLoadingRegister ? "Registrarse" : <CircularProgress />}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

FromTenant.propTypes = {
  handleTypeUser: PropTypes.func.isRequired,
}

export default FromTenant
