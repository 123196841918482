import { Box, Paper, Typography } from '@mui/material'
import TakePictureCustom from '../TakePicture'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/system'

export const Evidence = ({ description, name, showName, handleTakePicture }) => {
  const theme = useTheme()

  return <Paper elevation={2} sx={{ mt: 1 }}>
    <Box sx={{ p: 2, bgcolor: theme.palette.warning.main, color: theme.palette.warning.contrastText }}>
      <Box>
        <Typography variant="h6">{description}</Typography>
      </Box>
      <Box>
        <TakePictureCustom
          name={name}
          showName={showName}
          cameraWidth={640 / 2}
          cameraHeight={480 / 2}
          handleTakePicture={handleTakePicture}
        />
      </Box>
    </Box>
  </Paper>
}

Evidence.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showName: PropTypes.string.isRequired,
  handleTakePicture: PropTypes.func.isRequired
}
