import { Grid, TextareaAutosize } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import {
  addRegisterValues,
  selectRegisterValues,
} from "../../redux/slices/vehicles"

export const TextArea = () => {
  const dispatch = useDispatch()
  const values = useSelector(selectRegisterValues)

  const handleInputChange = (event) => {
    const target = event.target
    const { name, value } = target

    dispatch(
      addRegisterValues({
        ...values,
        [name]: value,
      })
    )
  }

  return (
    <Grid item xs={12}>
      <TextareaAutosize
        aria-label='description'
        placeholder='Comentarios'
        minRows={3}
        name='description'
        style={{ width: "100%" }}
        value={values.description || ""}
        onChange={handleInputChange}
      />
    </Grid>
  )
}
