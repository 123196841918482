import { useDispatch, useSelector } from "react-redux"
import { selectVerifyUser, setVerifyUser } from "../../redux/slices/users"
import { useGetDocumentsByUserQuery } from "../../redux/apis/documents"
import {
  useSetUserValidateMutation,
  useGetViewDocumentQuery,
} from "../../redux/apis/users"
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Icon,
  Chip,
  FormControlLabel,
  Switch,
  Button,
  Grid,
} from "@mui/material"
import {
  checkDaysToExpiration,
  checkDateNotMoreThreeMonths,
  formatDate,
} from "../../utils/handleData"
import { ShowDocuments } from "../General"
import { th } from "../../styles/tables"
import { red } from "@mui/material/colors"
import { useNavigate } from "react-router-dom"
import {
  selectDocuments,
  setVerifyDocument,
} from "../../redux/slices/documents"
import { useGetAddressesDefaultByUserQuery } from "../../redux/apis/addresses"
import { selectDefaultAddress } from "../../redux/slices/addresses"
import { useState } from "react"

// TODO add alert to confirm update and error cases
export const UsersVerification = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const verifyUser = useSelector(selectVerifyUser)
  const [checked, setChecked] = useState({})
  const { data, isLoading, isSuccess, isError, error } =
    useGetDocumentsByUserQuery(verifyUser?.id)
  const [setUserValidate, { isLoading: isSubmit }] =
    useSetUserValidateMutation()
  const documents = useSelector(selectDocuments)
  const {
    isLoading: isLoadingAddress,
    isSuccess: isSuccessAddress,
    isError: isErrorAddress,
    error: errorAddress,
  } = useGetAddressesDefaultByUserQuery(verifyUser?.id)
  const address = useSelector(selectDefaultAddress)

  const backToList = () => {
    navigate("/users")
  }

  const handleChange = (id) => (event) => {
    setChecked({ ...checked, [id]: event.target.checked })
    dispatch(setVerifyDocument({ id, validated: event.target.checked }))
  }

  const handleSubmit = () => {
    if (Object.keys(checked).length === 0) {
      return alert("No se a realizado ningún cambio")
    }
    setUserValidate({
      user_id: verifyUser.id,
      documents: checked,
    })
      .then(({ data, error }) => {
        if (data?.op) {
          dispatch(setVerifyUser(data.user))
          backToList()
        }
        if (error) console.log(error)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const threeMonthsDocument = ["proof_of_address", "account_state"]

  const handleExpiration = ({ document, type }) => {
    let aux
    let message
    if (threeMonthsDocument.includes(document.name)) {
      aux = checkDateNotMoreThreeMonths(document.expires_at)
      message = `Antigüedad del documento ${aux.days} días`
      if (!aux.valid) aux.days = aux.days - 90
    } else {
      aux = checkDaysToExpiration(document.expires_at)
      message = `Días faltantes ${aux.days}`
    }
    let icon = <Icon>watch_later</Icon>
    let color = "secondary"
    let result

    switch (type) {
      case "background":
        if (!aux.valid) {
          result = red[300]
        } else if (!document.validated) {
          result = red[100]
        }
        break
      case "chip":
        if (!aux.valid) {
          icon = <Icon>report</Icon>
          color = "error"
          message = `Documento vencido ${Math.abs(aux.days)} días`
        }
        result = (
          <>
            <Chip
              icon={<Icon>history_toggle_off</Icon>}
              color='primary'
              label={formatDate(document.expires_at)}
              sx={{ mr: 1 }}
            />
            <Chip icon={icon} color={color} label={message} />
          </>
        )
        break
      case "switch":
        if (!aux.valid) result = false
        else result = true
        break
    }

    return result
  }

  const rows = [
    {
      title: "Nombre",
      text: `${verifyUser.first_name} ${verifyUser.last_name}`,
    },
    { title: "Teléfono", text: verifyUser.phone },
    { title: "Correo", text: verifyUser.email },
    { title: "Ciudad", text: address?.region?.city },
    { title: "Dirección", text: address?.street },
    { title: "Código postal", text: address?.zip },
    { title: "Rol", text: verifyUser.role },
  ]

  let content
  if (isLoading || isLoadingAddress) {
    content = <CircularProgress size={60} />
  } else if (isError || isErrorAddress) {
    // TODO: handle error with list of errors or notifications
    console.log(errorAddress)
    content = <p>{error?.data?.message || errorAddress?.data?.message}</p>
  } else if (isSuccess && isSuccessAddress && data?.op) {
    content = (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {rows.map(({ title, text }) => (
              <TableRow key={title}>
                <TableCell component='th' scope='row' sx={th}>
                  {title}
                </TableCell>
                <TableCell align='right'>{text}</TableCell>
              </TableRow>
            ))}
            {documents.map((document) => (
              <TableRow
                key={document.id}
                sx={{
                  backgroundColor: handleExpiration({
                    document,
                    type: "background",
                  }),
                }}
              >
                <TableCell component='th' scope='row' sx={th}>
                  {document.type}
                </TableCell>
                <TableCell align='right'>
                  {document.expires_at &&
                    handleExpiration({
                      document,
                      type: "chip",
                    })}
                  <ShowDocuments
                    title={document.type}
                    path={document.path}
                    hook={useGetViewDocumentQuery}
                  />
                  {handleExpiration({
                    document,
                    type: "switch",
                  }) && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={document.validated}
                          onChange={handleChange(document.id)}
                        />
                      }
                      label={document.validated ? "Validado" : "No validado"}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <>
      {content}
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant='outlined'
            color='error'
            onClick={backToList}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant='contained'
            color='success'
            onClick={handleSubmit}
            disabled={isSubmit}
          >
            {!isSubmit || !isLoading || !isLoadingAddress ? (
              "Verificar"
            ) : (
              <CircularProgress size={24} />
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
