import { Routes, Route } from "react-router-dom"
import Welcome from "./views/Welcome"
import SignUp from "./views/SignUp"
import SignIn from "./views/SignIn/SignIn"
import Dashboard from "./views/Dashboard"
import Vehicles from "./views/Vehicles/Vehicles"
import RequireAuth from "./components/RequireAuth/RequireAuth"
import CardsView from "./views/CardsView"
import SpecialUsers from "./views/SpecialUsers/SpecialUsers"
import Users from "./views/Users/Users"
import { UsersList, UsersVerification } from "./components/Users"
import { VehiclesRevision } from "./views/VehiclesRevision/VehiclesRevision"
import { VehiclesRevision as VehiclesRevisionList } from "./components/VehicleRevision/VehiclesRevision"
import { VehicleVerification } from "./components/VehicleRevision/VehicleVerification"
import { NoMatch } from "./views/NoMatch/NoMatch"
import { VehiclesList, VehiclesRegister } from "./components/Vehicles"
import { useVehiclesGetByUserQuery } from "./redux/apis/vehicles"
import { VehicleRental } from "./views/VehicleRental/VehicleRental"
import { Rentals } from "./views/Rentals/Rentals"
import { RentalsList } from "./views/Rentals/RentalsList"
import { RentalDetails } from "./components/Rental/RentalDetails"

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Welcome />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/sign-in' element={<SignIn />}>
          <Route path='supervisor' element={<SpecialUsers />} />
        </Route>
        <Route
          path='/dashboard'
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path='/vehicles'
          element={
            <RequireAuth>
              <Vehicles />
            </RequireAuth>
          }
        >
          <Route
            index
            element={<VehiclesList hookQuery={useVehiclesGetByUserQuery} />}
          />
          <Route path='add' element={<VehiclesRegister />} />
        </Route>
        <Route
          path='/credit-cards'
          element={
            <RequireAuth>
              <CardsView />
            </RequireAuth>
          }
        />
        <Route
          path='/rentals'
          element={
            <RequireAuth>
              <Rentals />
            </RequireAuth>
          }
        >
          <Route path='vehicle' element={<VehicleRental />} />
          <Route path='list' element={<RentalsList />} />
          <Route path='details' element={<RentalDetails/>} />
        </Route>
        <Route
          path='/users'
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        >
          <Route index element={<UsersList />} />
          <Route path='verify' element={<UsersVerification />} />
        </Route>
        <Route
          path='/vehicles-revision'
          element={
            <RequireAuth>
              <VehiclesRevision />
            </RequireAuth>
          }
        >
          <Route index element={<VehiclesRevisionList />} />
          <Route path='verify' element={<VehicleVerification />} />
        </Route>
        <Route path='*' element={<NoMatch />} />
      </Routes>
    </div>
  )
}

export default App
