import { useSelector } from "react-redux"
import { selectIsAuth, selectViewsPermissions } from "../../redux/slices/auth"
import { useLocation, Navigate } from "react-router-dom"
import PropTypes from "prop-types"
import { NoMatch } from "../../views/NoMatch/NoMatch"
import { memo } from "react"

const RequireAuth = ({ children }) => {
  const isAuth = useSelector(selectIsAuth)
  const viewsPermissions = useSelector(selectViewsPermissions)
  const location = useLocation()
  let access = false

  if (!isAuth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/sign-in' state={{ from: location }} replace />
  }

  if (viewsPermissions) {
    viewsPermissions.map((permissions) => {
      if (location.pathname === permissions.path && permissions.can_access) {
        access = true
      }
    })
  }

  if (access) {
    return children
  }

  return <NoMatch />
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(RequireAuth)
