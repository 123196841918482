import { createSlice } from "@reduxjs/toolkit"
import { usersApi } from "../apis/users"

const initialState = {
  verifyUser: null,
  usersList: [],
}

const slice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    getVerifyUser: (state, action) => {
      localStorage.setItem("verifyUser", JSON.stringify(action.payload))
      state.verifyUser = action.payload
    },
    setVerifyUser: (state, action) => {
      state.usersList.map((user) => {
        if (user.id === action.payload.id) {
          user.validated = action.payload.validated
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      usersApi.endpoints.getUsers.matchFulfilled,
      (state, action) => {
        const { op, users } = action.payload
        if (op && users) {
          state.usersList = users
        }
      }
    )
  },
})

export const { getVerifyUser, setVerifyUser } = slice.actions
export default slice.reducer

export const selectVerifyUser = (state) =>
  state.user.verifyUser || JSON.parse(localStorage.getItem("verifyUser"))
export const selectUsersList = (state) => state.user.usersList
