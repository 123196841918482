import { createTheme } from "@mui/material"

export const dynFirst = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#8d7966",
      light: "rgb(163, 147, 132)",
      dark: "rgb(98, 84, 71)",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#a8a39d",
      light: "rgb(185, 181, 176)",
      dark: "rgb(117, 114, 109)",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    background: {
      default: "#e2ddd9",
      paper: "#ffffff",
    },
    error: {
      main: "#c84648",
      light: "rgb(211, 107, 108)",
      dark: "rgb(140, 49, 50)",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#ff6600",
      light: "rgb(255, 132, 51)",
      dark: "rgb(178, 71, 0)",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    selected: {
      main: "#ff6600",
    },
  },
})

export const hover = {
  boxShadow: "inset 0px 0px 20px 10px rgba(0,0,0,0.2)",
}

export const gridCenter = {
  display: "grid",
  placeContent: "center",
}

export const flexCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}
