import { Fab, Icon } from "@mui/material"
import PropTypes from "prop-types"
import styles from "./Modal.module.css"

export const MyModal = ({ children, open, handleModal }) => {
  return (
    <div className={!open ? styles.modal : styles.active}>
      <div className={styles.modalContent}>{children}</div>
      <Fab
        color='error'
        aria-label='close'
        sx={{ position: "absolute", right: 16 }}
        onClick={() => handleModal(false)}
      >
        <Icon baseClassName='material-icons'>close</Icon>
      </Fab>
    </div>
  )
}

MyModal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
}
