import { Outlet } from "react-router-dom"
import AppFrame from "../../components/AppFrame"

export const VehiclesRevision = () => {
  return (
    <AppFrame title='Vehículos'>
      <Outlet />
    </AppFrame>
  )
}
