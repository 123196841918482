import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import MobileStepper from "@mui/material/MobileStepper"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import SwipeableViews from "react-swipeable-views"
import { BASE_URI } from "../../constant/uris"
import { autoPlay } from "react-swipeable-views-utils"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"

export const VehicleCarousel = ({ photos, auto = false }) => {
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const [contentAutoPlay, setContentAutoPlay] = useState()
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews)
  const keys = Object.keys(photos)
  const maxSteps = keys.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStepChange = (step) => {
    console.log("step", step)
    setActiveStep(step)
  }

  useEffect(() => {
    if (auto) {
      setContentAutoPlay(
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {keys.map((step, index) => (
            <div key={photos[step].label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component='img'
                  sx={{
                    height: "100%",
                    display: "block",
                    overflow: "hidden",
                    width: "100%",
                  }}
                  src={`${BASE_URI}${photos[step].img}`}
                  alt={photos[step].label}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      )
    } else {
      setContentAutoPlay(
        <SwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {keys.map((step, index) => (
            <div key={photos[step].label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component='img'
                  sx={{
                    height: "100%",
                    display: "block",
                    overflow: "hidden",
                    width: "100%",
                  }}
                  src={`${BASE_URI}${photos[step].img}`}
                  alt={photos[step].label}
                />
              ) : null}
            </div>
          ))}
        </SwipeableViews>
      )
    }
  }, [auto, activeStep])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography>
          {
            photos[
              keys[activeStep === maxSteps - 1 ? maxSteps - 1 : activeStep]
            ].label
          }
        </Typography>
      </Paper>
      {contentAutoPlay}
      <MobileStepper
        steps={maxSteps}
        position='static'
        activeStep={activeStep}
        nextButton={
          <Button
            size='small'
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Siguiente
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Atrás
          </Button>
        }
      />
    </Box>
  )
}

VehicleCarousel.propTypes = {
  photos: PropTypes.object.isRequired,
  auto: PropTypes.bool,
}
