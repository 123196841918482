import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BASE_URI } from "../../../constant/uris"

// in this api is use RTK toolkit from redux-toolkit
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URI,
})

// import retry from "@reduxjs/toolkit/query/react" if you want use retry
// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 })

export const regionsApi = createApi({
  reducerPath: "regions",
  baseQuery,
  endpoints: (builder) => ({
    regionsList: builder.query({
      query: () => "regions/list",
    }),
  }),
})

export const { useRegionsListQuery } = regionsApi
