import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material"
import { useVehicleGetColorsQuery } from "../../redux/apis/vehicles"
import PropTypes from "prop-types"

export const SelectColor = ({ selected, handleSelectChange }) => {
  const { data, isLoading, isSuccess, isError, error } =
    useVehicleGetColorsQuery()

  let options = []
  if (isLoading) {
    options = [{ id: "", name: "Cargando..." }]
  } else if (isError) {
    options = [{ id: "", name: "Error..." }]
    console.log(error)
  } else if (isSuccess) {
    options = data.colors.map((color) => ({
      id: color.id,
      name: color.name,
      color: color.addon,
    }))
  }

  return (
    <FormControl required variant='filled' disabled={!isSuccess} fullWidth>
      <InputLabel id={`colors-label`}>Color</InputLabel>
      <Select
        labelId='colors-label'
        name='color'
        label='Color'
        value={selected || ""}
        onChange={handleSelectChange}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Grid container justify='flex-end' alignItems='center' spacing={1}>
              <Grid item>
                <Paper
                  variant='outlined'
                  sx={{
                    backgroundColor: option.color,
                    height: 28,
                    width: 28,
                  }}
                />
              </Grid>
              <Grid item>{option.name}</Grid>
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

SelectColor.propTypes = {
  selected: PropTypes.string,
  handleSelectChange: PropTypes.func.isRequired,
}
