import { Button, Icon, Modal } from "@mui/material"
import { Box } from "@mui/system"
import CreditCard from "./CreditCard"
import { useDispatch, useSelector } from "react-redux"
// TODO change modal to work with redux hooks helpers
import { handleModal, selectOpenModal } from "../../redux/slices/credit_cards"
import { modalStyle } from "../../styles/modal"

export const AddCard = () => {
  const dispatch = useDispatch()
  const open = useSelector(selectOpenModal)
  const handleOpen = () => dispatch(handleModal(true))
  const handleClose = () => dispatch(handleModal(false))

  return (
    <>
      <Button startIcon={<Icon>add_box</Icon>} onClick={handleOpen}>
        Tarjeta
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Tarjeta"
        aria-describedby="Tarjeta para realizar pagos o recibir pagos"
      >
        <Box sx={modalStyle}>
          <CreditCard />
        </Box>
      </Modal>
    </>
  )
}
