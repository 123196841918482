import AppFrame from "../../components/AppFrame/AppFrame"
import FormRegister from "../../components/FormRegister/FormRegister"

const SignUp = () => {
  return (
    <AppFrame title='Registró'>
      <FormRegister />
    </AppFrame>
  )
}

export default SignUp
