import {
	Avatar,
	Button,
	CircularProgress,
	Icon,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { useVehiclesGetListQuery } from "../../redux/apis/vehicles";
import { useSelector, useDispatch } from "react-redux";
import { selectVehicles, getVerifyVehicle } from "../../redux/slices/vehicles";
import { BASE_URI } from "../../constant/uris";
import { green, red } from "@mui/material/colors";
import { selectPermissionsByPath } from "../../redux/slices/auth";
import { useNavigate } from "react-router-dom";

const tableHeads = [
	"imagen",
	"Vehículo",
	"Placa",
	"Tipo",
	"Verificado",
	"Herramientas",
];

export const VehiclesRevision = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const permissions = useSelector(() => selectPermissionsByPath("/users"))();
	const { data, isLoading, isSuccess, isError, error } =
		useVehiclesGetListQuery();
	const vehicles = useSelector(selectVehicles);

	const handleVerify = (vehicle) => {
		dispatch(getVerifyVehicle(vehicle));
		navigate("/vehicles-revision/verify");
	};

	let component;
	if (isLoading) {
		component = <CircularProgress size={60} />;
	} else if (isSuccess && data?.op) {
		component = (
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							{tableHeads.map((head) => (
								<TableCell key={head}>{head}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{vehicles?.map((vehicle) => (
							<TableRow
								key={vehicle.id}
								sx={{
									backgroundColor: vehicle.validated ? "inherit" : red[100],
								}}
							>
								<TableCell>
									<Avatar
										alt='Frente del carro'
										src={`${BASE_URI}${vehicle.photos.FRONT.img}`}
									/>
								</TableCell>
								<TableCell>{`${vehicle.properties.brand} ${vehicle.properties.model}`}</TableCell>
								<TableCell>{vehicle.plates}</TableCell>
								<TableCell>{vehicle.properties.type}</TableCell>
								<TableCell align='right'>
									{vehicle.validated ? (
										<Icon
											baseClassName='material-icons'
											sx={{ color: green[500] }}
										>
											done
										</Icon>
									) : (
										<Icon
											baseClassName='material-icons'
											sx={{ color: red[500] }}
										>
											close
										</Icon>
									)}
								</TableCell>
								<TableCell>
									{permissions.can_update && (
										<Button
											variant='contained'
											color='primary'
											onClick={() => handleVerify(vehicle)}
										>
											Revisar
										</Button>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	} else if (isError) {
		console.log(error);
	}

	return <>{component}</>;
};
