import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addError } from "../../redux/slices/errors";
import { useVehiclesAddMutation } from "../../redux/apis/vehicles";
import { SelectColor } from "./SelectColor";
import { SelectYears } from "./SelectYears";
import { Inputs } from "./Inputs";
import CustomSelects from "./CustomSelects";
import { memo } from "react";
import { TextArea } from "./TextArea";
import { CheckBoxes } from "./CheckBoxes";
import { Photos } from "./Photos";
import {
	selectRegisterValues,
	addRegisterValues,
} from "../../redux/slices/vehicles";
import { useNavigate } from "react-router-dom";
import { SelectCards } from "../CreditCard/SelectCards";
import { SelectAddress } from "../Address/SelectAddress";
import { selectAddress as getAddress } from "../../redux/slices/addresses";
import { selectCard as getCard } from "../../redux/slices/credit_cards";

const VehiclesRegister = () => {
	const dispatch = useDispatch();
	const values = useSelector(selectRegisterValues);
	const navigate = useNavigate();
	const [vehicleAdd, { isLoading: isLoadingAddVehicle }] =
		useVehiclesAddMutation();
	const address = useSelector(getAddress);
	const card = useSelector(getCard);

	const handleInputChange = (event) => {
		const target = event.target;
		let { name, value } = target;

		dispatch(
			addRegisterValues({
				...values,
				properties: {
					...values.properties,
					[name]: value,
				},
			}),
		);
	};

	const handleCancel = () => {
		navigate("/vehicles");
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const aux = structuredClone(values);
		aux.properties.documents = [
			{
				name: "circulation_card",
				label: "Tarjeta de circulación",
				img: values.documents.circulation_card,
				expires_at: new Date(values.documents.circulation_card_expiration),
				type: "CIRCULATION_CARD",
			},
		];
		const data = { vehicle: aux, payment_id: card, address_id: address };
		vehicleAdd(data)
			.then((res) => {
				const { error, data } = res;
				if (error) {
					return dispatch(
						addError({
							kind: "fetch_error",
							severity: "error",
							message: error.data,
						}),
					);
				}

				if (data?.op) {
					addError({
						kind: "fetch_success",
						severity: "success",
						message: data.message,
					});
					navigate("/vehicles");
				}
			})
			.catch((err) => {
				console.log("catch -->", err);
				dispatch(
					addError({
						kind: "fetch_error",
						severity: "error",
						message:
							"Hubo un problema al tratar de registrar el vehículo por favor inténtelo más tarde",
					}),
				);
			});
	};

	return (
		<Box container component="form">
			<Typography variant="h4">Información del Vehículo</Typography>

			<CustomSelects />

			<SelectColor
				selected={values.properties.color}
				handleSelectChange={handleInputChange}
			/>

			<SelectYears
				selected={values.properties.year}
				handleSelectChange={handleInputChange}
			/>

			<SelectCards style="filled" />

			<Inputs />

			<SelectAddress />

			<TextArea />

			<CheckBoxes />

			<Photos />

			<Grid item container spacing={1} sx={{ mt: 2 }}>
				<Grid item>
					<Button variant="contained" color="error" onClick={handleCancel}>
						Cancelar
					</Button>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						color="success"
						type="submit"
						onClick={handleSubmit}
						disabled={isLoadingAddVehicle}
					>
						Guardar
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export default memo(VehiclesRegister);
