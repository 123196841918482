import { createSlice } from "@reduxjs/toolkit"
import { authApi } from "../apis/auth"

const initialState = {
  user: null,
  viewsPermissions: [],
  isAuth: false,
  authValues: {
    email: "",
    password: "",
    role_id: "",
  },
}

const slice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    logout: () => {
      localStorage.clear()
      return initialState
    },
    setAuthValues: (state, action) => {
      state.authValues = { ...state.authValues, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.signIn.matchFulfilled,
      (state, action) => {
        const { op, user, viewsPermissions } = action.payload
        if (op === true) {
          localStorage.setItem("user", JSON.stringify(user))
          localStorage.setItem("isAuth", true)
          localStorage.setItem(
            "viewsPermissions",
            JSON.stringify(viewsPermissions)
          )

          state.user = user
          state.isAuth = true
          state.viewsPermissions = viewsPermissions
        }
      }
    )
  },
})

export const { logout, setAuthValues } = slice.actions
export default slice.reducer

export const selectAuthValues = (state) => state.auth.authValues
export const selectUser = (state) => {
  if (state.auth.user) {
    return state.auth.user
  } else {
    return JSON.parse(localStorage.getItem("user"))
  }
}
export const selectIsAuth = (state) => {
  if (state.auth.isAuth) {
    return state.auth.isAuth
  } else {
    return localStorage.getItem("isAuth") ? true : false
  }
}
export const selectViewsPermissions = (state) => {
  if (state.auth.viewsPermissions.length) {
    return state.auth.viewsPermissions
  } else {
    return JSON.parse(localStorage.getItem("viewsPermissions"))
  }
}

export const selectPermissionsByPath = (path) => (state) => {
  if (state?.auth.viewsPermissions.length) {
    return state.auth.viewsPermissions.filter((permission) => {
      return permission.path === path
    })[0]
  } else {
    return JSON.parse(localStorage.getItem("viewsPermissions")).filter(
      (permission) => {
        return permission.path === path
      }
    )[0]
  }
}
