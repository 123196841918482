import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material"
import Icon from "@mui/material/Icon"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { selectUser, selectViewsPermissions } from "../../redux/slices/auth"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { memo } from "react"
import { useTheme } from "@mui/system"

const SideBar = ({
  window,
  isAuth,
  mobileOpen,
  drawerWidth,
  handleDrawerToggle,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const viewsPermissions = useSelector(selectViewsPermissions)
  const user = useSelector(selectUser)

  const handleListItemClick = (path) => {
    navigate(path)
  }

  const drawer = (
    <Box>
      <Toolbar />
      <List>
        {viewsPermissions &&
          viewsPermissions.map((permissions) => {
            if (!permissions.sub_route) {
              return (
                <ListItemButton
                  key={permissions.name}
                  component='a'
                  onClick={() => handleListItemClick(permissions.path)}
                  sx={
                    location.pathname === permissions.path
                      ? {
                          bgcolor: theme.palette.primary.dark,
                        }
                      : {
                          ":hover": {
                            boxShadow: 6,
                          },
                        }
                  }
                  disabled={
                    !isAuth ||
                    !user.validated ||
                    location.pathname === permissions.path
                  }
                >
                  <ListItemIcon>
                    <Icon baseClassName='material-icons'>
                      {permissions.icon}
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary={permissions.text} />
                </ListItemButton>
              )
            }
          })}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined
  return (
    <>
      {isAuth && (
        <Box
          component='nav'
          sx={{
            display: "flex",
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label='side left menu'
        >
          <Drawer
            container={container}
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant='permanent'
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            anchor='left'
            open
          >
            {drawer}
          </Drawer>
        </Box>
      )}
    </>
  )
}

SideBar.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  window: PropTypes.func,
  mobileOpen: PropTypes.bool.isRequired,
  drawerWidth: PropTypes.number,
  handleDrawerToggle: PropTypes.func,
}

export default memo(SideBar)
