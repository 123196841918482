import { Container } from "@mui/material"
import { Link } from "react-router-dom"

export const NoMatch = () => {
  return (
    <Container>
      <h2>No se encontró nada aquí!</h2>
      <p>
        <Link to='/'>Regresa a la pagina principal</Link>
      </p>
    </Container>
  )
}
