import { memo, useState } from "react"
import {
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Alert,
  Box,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import AccountCircle from "@mui/icons-material/AccountCircle"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { logout, selectUser } from "../../redux/slices/auth"
import { useSelector } from "react-redux"
import { useTheme } from "@mui/system"
import { clearCard } from "../../redux/slices/credit_cards"

const SignUpAndSignIn = ({ handleSingUpButton, handleSingInButton }) => {
  return (
    <div>
      <Button color='inherit' onClick={handleSingUpButton}>
        Regístrate
      </Button>
      <Button color='inherit' onClick={handleSingInButton}>
        Accede
      </Button>
    </div>
  )
}

const LoginMenu = ({
  anchorEl,
  handleMenu,
  handleClose,
  handleLogoutButton,
  user,
}) => {
  return (
    <div>
      <IconButton
        size='large'
        aria-label='account of current user'
        aria-controls='menu-app-bar'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <AccountCircle />
        <Typography variant='body1' noWrap>
          {user.name}
        </Typography>
      </IconButton>
      <Menu
        id='menu-app-bar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogoutButton}>Salir</MenuItem>
      </Menu>
    </div>
  )
}

const TopBar = ({ title, isAuth, handleDrawerToggle }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const user = useSelector(selectUser)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSingUpButton = () => {
    navigate("/sign-up")
  }

  const handleSingInButton = () => {
    navigate("/sign-in")
  }

  const handleLogoutButton = () => {
    dispatch(clearCard())
    dispatch(logout())
    navigate("/sign-in", { replace: true })
  }

  return (
    <AppBar
      position='fixed'
      color='inherit'
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: theme.palette.primary.main,
      }}
    >
      <Toolbar>
        {isAuth && (
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {/* Icon in left */}
        <Box
          sx={{
            flexGrow: 1,
            backgroundImage: "url(/images/logo-dyn.svg)",
            backgroundSize: "auto 90%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",
            height: 64,
          }}
        />
        <Box sx={{ flexGrow: 1, paddingLeft: 12 }}>
          <Typography variant='h6' noWrap component='div'>
            {title}
          </Typography>
        </Box>
        {isAuth && !user.validated ? (
          <Alert severity='warning'>Usuario no verificado</Alert>
        ) : null}
        {isAuth ? (
          <LoginMenu
            anchorEl={anchorEl}
            handleMenu={handleMenu}
            handleClose={handleClose}
            handleLogoutButton={handleLogoutButton}
            user={user}
          />
        ) : (
          <SignUpAndSignIn
            handleSingUpButton={handleSingUpButton}
            handleSingInButton={handleSingInButton}
          />
        )}
      </Toolbar>
    </AppBar>
  )
}

SignUpAndSignIn.propTypes = {
  handleSingUpButton: PropTypes.func.isRequired,
  handleSingInButton: PropTypes.func.isRequired,
}

LoginMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleMenu: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLogoutButton: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

TopBar.propTypes = {
  title: PropTypes.string.isRequired,
  isAuth: PropTypes.bool.isRequired,
  // drawerWidth: PropTypes.number.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
}

export default memo(TopBar)
