import { TextField, Button, Box } from "@mui/material"
import SaveIcon from "@mui/icons-material/Save"
import ErrorList from "../ErrorList"
import { useSignInMutation } from "../../redux/apis/auth"
import SelectRole from "../SelectRole/SelectRole"
import { areErrorsInputs } from "../../utils/validations"
import { useDispatch, useSelector } from "react-redux"
import { addError } from "../../redux/slices/errors"
import { setAuthValues, selectAuthValues } from "../../redux/slices/auth"
import { useNavigate, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

const LoginForm = ({ isSpecial }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const from = location.state?.from?.pathname || "/"
  const [signIn, { isLoading }] = useSignInMutation()

  const handleCancel = () => {
    navigate("/")
  }

  const handleChange = (prop) => (event) => {
    if (prop === "email") {
      dispatch(setAuthValues({ email: event.target.value }))
    } else {
      dispatch(setAuthValues({ [prop]: event.target.value }))
    }
  }

  const values = useSelector(selectAuthValues)

  const signInUser = (event) => {
    event.preventDefault()
    const { op, errors } = areErrorsInputs(values)

    if (op) {
      dispatch(addError(errors))
      return false
    }

    signIn(values).then((res) => {
      const { error, data } = res
      if (error) {
        if (error.status === "FETCH_ERROR") {
          dispatch(
            addError({
              kind: "fetch_error",
              severity: "error",
              message:
                "No se pudo conectar con el servidor, por favor inténtelo mas tarde",
            })
          )
        } else {
          if (typeof error.data === "object") {
            dispatch(
              addError({
                kind: "fetch_unknown_error_object",
                severity: "error",
                message: error.data.message,
              })
            )
          } else {
            dispatch(
              addError({
                kind: "fetch_unknown_error",
                severity: "error",
                message: error.data,
              })
            )
          }
        }
      }

      if (data && data.op === true) {
        navigate(from, { replace: true })
      } else if (data && data.op === false) {
        dispatch(
          addError({
            kind: "operation_error",
            severity: "error",
            message: data.message,
          })
        )
      }
    })
  }

  return (
    <Box component='form' onSubmit={signInUser}>
      <ErrorList />
      {isSpecial ? null : (
        <SelectRole role_id={values.role_id} handleChange={handleChange} />
      )}
      <Box>
        <TextField
          fullWidth
          required
          type='text'
          label='Correo'
          variant='filled'
          value={values.email}
          onChange={handleChange("email")}
        />
        <TextField
          fullWidth
          required
          type='password'
          label='Contraseña'
          variant='filled'
          value={values.password}
          onChange={handleChange("password")}
        />
      </Box>
      <Box mt={2} sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Button variant='contained' onClick={handleCancel}>
            Cancelar
          </Button>
        </Box>
        <Box>
          {!isLoading ? (
            <Button
              type='submit'
              variant='contained'
              color='success'
              onClick={signInUser}
            >
              Login
            </Button>
          ) : (
            <Button startIcon={<SaveIcon />} variant='outlined' disabled>
              Login ...
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

LoginForm.propTypes = {
  isSpecial: PropTypes.bool,
}

export default LoginForm
