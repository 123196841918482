import { Box, Stack, Alert } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import { selectErrors, removeError } from "../../redux/slices/errors"

const ErrorList = () => {
  const errors = useSelector(selectErrors)
  const dispatch = useDispatch()

  return (
    <Box mb={2}>
      {/* ==== Steps Headers ==== */}
      <Stack sx={{ width: "100%" }} spacing={1}>
        {errors
          ? errors.map((error) => (
              <Alert
                key={error.kind}
                severity={error.severity}
                onClose={() => {
                  dispatch(removeError(error.kind))
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: error.message }} />
              </Alert>
            ))
          : null}
      </Stack>
    </Box>
  )
}

export default ErrorList
