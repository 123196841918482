import { Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	addRegisterValues,
	selectRegisterValues,
} from "../../redux/slices/vehicles";
import { inputs, inputsDirect } from "../../constant/vehicleData";

export const Inputs = () => {
	const dispatch = useDispatch();
	const values = useSelector(selectRegisterValues);

	const handleInputChange = (event) => {
		const target = event.target;
		let { name, value } = target;

		dispatch(
			addRegisterValues({
				...values,
				properties: {
					...values.properties,
					[name]: value,
				},
			}),
		);
	};

	const handleInputDirectChange = (event) => {
		const target = event.target;
		let { name, value } = target;
		if (name === "plates") {
			if (value.length > 7) {
				value = value.slice(0, 7);
			}

			if (value.length === 3) {
				value = `${value}-`;
			}

			value = value.toUpperCase();
		}
		dispatch(
			addRegisterValues({
				...values,
				[name]: value,
			}),
		);
	};

	return (
		<>
			{inputs.map((input) => (
				<Grid item xs={12} key={input.name}>
					<TextField
						fullWidth
						name={input.name}
						label={input.label}
						variant='filled'
						value={values?.properties[input.name] || ""}
						onChange={handleInputChange}
						type={input.type}
					/>
				</Grid>
			))}

			{inputsDirect.map((input) => (
				<Grid item xs={12} key={input.name}>
					<TextField
						fullWidth
						required
						name={input.name}
						label={input.label}
						variant='filled'
						value={values[input.name] || ""}
						onChange={handleInputDirectChange}
						type={input.type}
					/>
				</Grid>
			))}
		</>
	);
};
