import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "../../utils/headersApi"

export const viewsPermissionsApi = createApi({
  reducerPath: "viewsPermissionsApi",
  baseQuery,
  endpoints: (builder) => ({
    viewsPermissions: builder.query({
      query: (roleId) => `views-permissions/${roleId}`,
    }),
  }),
})

export const { useViewsPermissionsQuery } = viewsPermissionsApi
