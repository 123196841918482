export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
export const validatePassword = (password) => {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/
  return re.test(password)
}
export const validatePhone = (phone) => {
  const re = /^\d{10}$/
  return re.test(phone)
}

export const validateNotEmpty = (value) => {
  return value.length > 0
}

export const areErrorsInputs = (values) => {
  let _errors = []
  let op = false
  Object.keys(values).forEach((element) => {
    if (!validateNotEmpty(values[element])) {
      op = true

      _errors.push({
        kind: element,
        severity: "error",
        message: `El campo ${element} es requerido`,
      })

      return { op, errors: _errors }
    }

    if (element === "email") {
      if (!validateEmail(values[element])) {
        op = true
        _errors.push({
          kind: element,
          severity: "error",
          message: `El correo no es valido`,
        })
      }
    }

    if (element === "phone") {
      if (!validatePhone(values[element])) {
        op = true
        _errors.push({
          kind: element,
          severity: "error",
          message: `El teléfono no es valido debe de tener al menos 10 dígitos`,
        })
      }
    }

    if (element === "password") {
      if (!validatePassword(values[element])) {
        op = true
        _errors.push({
          kind: element,
          severity: "error",
          message: `* La contraseña debe tener al menos 8 caracteres. <br/> * Una
              letra mayúscula, una letra minúscula, un número <br /> * Un
              carácter especial como los siguientes [ @ $ ! % * ? & ].`,
        })
      }
    }
  })

  // if (values.password !== values.confirm) {
  //   op = true
  //   _errors.push({
  //     kind: "confirm",
  //     severity: "error",
  //     message: `Las contraseñas no coinciden`,
  //   })
  // }

  return { op, errors: _errors }
}
