import {
  Avatar,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material"
import { useGetRentalsListByUserQuery } from "../../redux/apis/rentals"
import { BASE_URI } from "../../constant/uris"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { handleRental, selectRentalsList } from "../../redux/slices/rentals"
import { RENTAL_STATUS } from "../../constant/status"
import { hover } from "../../styles/theme"

export const RentalsList = () => {
  const { isLoading } = useGetRentalsListByUserQuery()
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const rentals = useSelector(selectRentalsList)

  const handleRowClick = (row) => {
    dispatch(handleRental(row))
    navigate(`/rentals/details`)
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Carro </TableCell>
            <TableCell>Marca </TableCell>
            <TableCell>Modelo </TableCell>
            <TableCell>Estado </TableCell>
            <TableCell align='right'>Fecha de la renta</TableCell>
            <TableCell align='right'>Fecha de la entrega</TableCell>
            <TableCell align='right'>Deposito</TableCell>
            <TableCell align='right'>Costo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={7}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            rentals?.map((row) => {
              let bgColor = "inherit"
              if (row.status.name === RENTAL_STATUS.FINISHED) {
                bgColor = theme.palette.success.light
              } else if (
                row.status.name === RENTAL_STATUS.FINISHED_WITH_PENALTY
              ) {
                bgColor = theme.palette.warning.light
              }

              return (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": hover,
                    cursor: "pointer",
                    backgroundColor: bgColor,
                  }}
                  onClick={() => handleRowClick(row)}
                >
                  <TableCell>
                    <Avatar
                      alt='vehicle'
                      src={BASE_URI + row.vehicle.photos.FRONT.img}
                    />
                  </TableCell>
                  <TableCell>{row.vehicle.properties.brand}</TableCell>
                  <TableCell>
                    {row.vehicle.properties.model +
                      " " +
                      row.vehicle.properties.year}
                  </TableCell>
                  <TableCell align='right'>{row.status.addon}</TableCell>
                  <TableCell align='right'>{row.start}</TableCell>
                  <TableCell align='right'>{row.end}</TableCell>
                  <TableCell align='right'>{row.deposit}</TableCell>
                  <TableCell align='right'>{row.collect}</TableCell>
                </TableRow>
              )
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
