import AppFrame from "../../components/AppFrame/AppFrame"
import { memo } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { Box, Button, Icon } from "@mui/material"
import { useLocation } from "react-router-dom"
import { gridCenter } from "../../styles/theme"

const Vehicles = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleAddVehicle = () => {
    navigate("/vehicles/add")
  }
  return (
    <AppFrame title='Vehículos'>
      {location.pathname === "/vehicles" && (
        <Box sx={gridCenter}>
          <Button
            startIcon={<Icon>add_box</Icon>}
            onClick={handleAddVehicle}
            sx={{ mb: 2, fontSize: "1.5rem" }}
            variant='outlined'
          >
            Agregar Vehículo
          </Button>
        </Box>
      )}
      <Outlet />
    </AppFrame>
  )
}

export default memo(Vehicles)
