import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  TextField,
  Typography,
} from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useVehicleRentalMutation } from "../../redux/apis/vehicles"
import { selectCard } from "../../redux/slices/credit_cards"
import { handleModal } from "../../redux/slices/helpers"
import { selectVehicleToRental } from "../../redux/slices/vehicles"
import {
  checkIfNumber,
  dateParse,
  getNowLocalDate,
} from "../../utils/handleData"
import { SelectCards } from "../CreditCard/SelectCards"

export const Rental = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const vehicle = useSelector(selectVehicleToRental)
  // add two hours to today and get local time
  const auxDay = getNowLocalDate()
  auxDay.setHours(auxDay.getHours() + 2)
  auxDay.setMinutes(0)
  auxDay.setSeconds(0)
  const toDay = auxDay.toISOString().split(".")

  const [time, setTime] = useState(toDay[0])
  const [values, setValues] = useState({
    vehicle: { id: vehicle.id },
    partner: { id: vehicle.user_id },
    start: dateParse(toDay[0]),
    days: 1,
  })
  const [vehicleRental, { isLoading }] = useVehicleRentalMutation()
  const card = useSelector(selectCard)

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "days" && value < 1) return
    if (name === "start" && value < toDay[0]) return
    if (name === "start") {
      setValues({ ...values, [name]: dateParse(value) })
      setTime(value)
      return
    }

    setValues({ ...values, [name]: value })
  }

  // TODO: validate inputs and date to all is correct
  const handleRental = () => {
    if (!card) return
    if (!values.start) return
    if (values.days < 1) return

    values.client_payment = card
    vehicleRental(values)
      .then((res) => {
        const { op } = res.data
        if (op) {
          dispatch(handleModal(false))
          navigate("/rentals/list")
        }
      })
      .catch((err) => console.log(err))
  }

  return (
    <Box>
      <Typography variant='h4' sx={{ textAlign: "center" }}>
        {vehicle.properties.brand} {vehicle.properties.model}{" "}
        {vehicle.properties.year}
      </Typography>
      <Typography variant='h6' sx={{ textAlign: "center" }}>
        Costo por día {vehicle.properties.price}
      </Typography>
      <Box>
        <Typography variant='body1' component='div'>
          <ol>
            <li>Seleccione la tarjeta con la que desea pagar</li>
            <li>Seleccione la fecha en que quire recoger el vehículo</li>
            <li>Ponga los días que ocupara el vehículo</li>
            <li>Proceda a pagar</li>
          </ol>
        </Typography>
      </Box>
      {/* component of select card */}
      <SelectCards />
      {/* ------------------------ */}
      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextField
            fullWidth
            sx={{ marginTop: "1rem" }}
            name='start'
            label='Reservación Día y Hora'
            type='datetime-local'
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              value: time,
              min: toDay[0],
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            fullWidth
            name='days'
            type='number'
            sx={{ marginTop: "1rem" }}
            label='Días que lo desea rentar'
            defaultValue={1}
            inputProps={{
              inputMode: "numeric",
              min: 1,
            }}
            onKeyDown={checkIfNumber}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Button
          variant='contained'
          fullWidth
          color='success'
          startIcon={<Icon>attach_money</Icon>}
          onClick={handleRental}
        >
          Pagar [{" "}
          {vehicle.properties.price * (values.days > 0 ? values.days : 1)} ]
          {!isLoading ? null : <CircularProgress />}
        </Button>
      </Box>
    </Box>
  )
}
