import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { baseQuery } from "../../utils/headersApi"

export const addressesApi = createApi({
  reducerPath: "addressesApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAddresses: builder.query({
      query: () => "/addresses",
    }),
    getAddressesDefaultByUser: builder.query({
      query: (userId) => ({
        url: `/addresses/default/${userId}`,
        method: "GET",
      }),
    }),
    addAddress: builder.mutation({
      query: (values) => {
        values.region = { id: values.selectedRegion }
        return {
          url: "/addresses",
          method: "POST",
          body: { address: values, Addresses: { type: 1 } },
        }
      },
    }),
    getAddressesByUser: builder.query({
      query: () => ({
        url: "/addresses/user",
        method: "GET",
      }),
    }),
  }),
})

export const {
  useGetAddressesQuery,
  useGetAddressesDefaultByUserQuery,
  useAddAddressMutation,
  useGetAddressesByUserQuery,
} = addressesApi
