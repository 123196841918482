import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "../../utils/headersApi"

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: (post) => ({
        url: `users/sign-in`,
        method: "POST",
        body: post,
      }),
    }),
    getRole: builder.query({
      query: (role) => ({
        url: `roles/${role}`,
      }),
    }),
  }),
})

export const { useSignInMutation, useGetRoleQuery } = authApi
