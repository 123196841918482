import { Box, Divider, Icon, Paper } from "@mui/material"
import { commentStyles } from "../../styles/comments"
import PropTypes from "prop-types"

export const Comment = ({ author, text }) => {
  return (
    <Box>
      <Paper>
        <Box sx={commentStyles.header}>
          <Icon sx={{ fontSize: 14 }}>comment</Icon> {author}
        </Box>
        <Divider />
        <Box sx={commentStyles.text}>{text}</Box>
      </Paper>
    </Box>
  )
}

Comment.propTypes = {
  author: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
