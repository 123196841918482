import { Chip, Icon } from "@mui/material"
import PropTypes from "prop-types"

export const ShowDateValidate = ({ days, formate }) => {
  let icon = <Icon>watch_later</Icon>
  let color = "secondary"
  let message = `Días faltantes ${days.days}`

  if (!days.valid) {
    icon = <Icon>report</Icon>
    color = "error"
    message = `Documento vencido ${Math.abs(days.days)} dias`
  }

  return (
    <>
      <Chip
        icon={<Icon>history_toggle_off</Icon>}
        color="primary"
        label={formate}
        sx={{ mr: 1 }}
      />
      <Chip icon={icon} color={color} label={message} />
    </>
  )
}

ShowDateValidate.propTypes = {
  days: PropTypes.object,
  formate: PropTypes.string,
}
