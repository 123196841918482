import { useState } from 'react'
import { Box, Button, Icon } from '@mui/material'
import { EvidenceDialog } from './EvidenceDialog'
import { Evidence } from './Evidence'
import { useSelector, useDispatch } from 'react-redux'
import { selectEvidences, addEvidence, setEvidenceImage } from '../../redux/slices/evidences'
import { gridCenter } from '../../styles/theme'

export const EvidencesList = () => {
  const initialData = {
    key: 0,
    name: '',
    showName: '',
    newEvidence: false,
    description: '',
    img: '',
  }
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(initialData)
  const evidences = useSelector(selectEvidences)
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value,
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleAddEvidence = () => {
    // TODO: change alert for a snackbar or something
    if (!isFullFeedEvidence()) {
      handleClose()
      return alert('Debes de llenar la descripción y tomar una foto')
    }

    const key = Object.keys(evidences).length + 1
    const name = "evidence_" + (Object.keys(evidences).length + 1)
    const showName = "Evidencia " + (Object.keys(evidences).length + 1)

    setData({
      ...data,
      key: key,
      name: name,
      showName: showName,
      newEvidence: true,
    })

    dispatch(addEvidence({
      key,
      name,
      showName,
      description: data.description
    }))

    handleClose()
  }


  const handleTakePicture = ({ base64 }) => {
    setData({
      ...data,
      img: base64,
    })

    dispatch(setEvidenceImage({ key: data.key, img: base64 }))
    emptyEvidence()
  }

  const isFullFeedEvidence = () => {
    if (!data.newEvidence) {
      return true
    }

    return console.log(data.img !== '' && data.description !== '')
  }

  const emptyEvidence = () => {
    setData(initialData)
  }

  return (
    <Box>
      <Box sx={gridCenter}>
        <Button
          startIcon={<Icon>add_a_photo</Icon>}
          color='inherit'
          variant='outlined'
          aria-label='add a picture'
          onClick={handleOpen}
          sx={{ m: 2 }}
        >
          Agregar evidencia
        </Button>
      </Box>
      <Box sx={gridCenter}>
        {evidences.map((evidence) => {
          return (
            <Evidence
              key={evidence.key}
              description={evidence.description}
              name={evidence.name}
              showName={evidence.showName}
              handleTakePicture={handleTakePicture}
            />
          )
        })}
      </Box>

      <EvidenceDialog
        open={open}
        handleClose={handleClose}
        handleChange={handleChange}
        description={data.description}
        handleAdd={handleAddEvidence}
      />

    </Box>
  )
}
