import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"
import ListSubheader from "@mui/material/ListSubheader"
import IconButton from "@mui/material/IconButton"
import InfoIcon from "@mui/icons-material/Info"
import PropTypes from "prop-types"
import { useGetEvidencesQuery } from "../../redux/apis/rentals"
import { CircularProgress } from "@mui/material"
import { BASE_URI } from "../../constant/uris"

export default function ShowEvidence({ rental_id }) {
  const { data, isLoading, isSuccess, isError, error } =
    useGetEvidencesQuery(rental_id)

  let content = null

  if (isLoading) {
    content = <CircularProgress />
  } else if (isSuccess) {
    console.log("evidences", data.evidences)
    content = data.evidences.map((item) => (
      <ImageListItem key={item.key}>
        <img
          src={`${BASE_URI}${item.img}?w=248&fit=crop&auto=format`}
          srcSet={`${BASE_URI}${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
          alt={item.description}
          loading='lazy'
        />
        <ImageListItemBar
          title={item.description}
          subtitle={item.showName}
          actionIcon={
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
              aria-label={`info about ${item.description}`}
            >
              <InfoIcon />
            </IconButton>
          }
        />
      </ImageListItem>
    ))
  } else if (isError) {
    content = <div>{error.data.message}</div>
  }

  console.log("rental_id", rental_id, content)

  return (
    <ImageList sx={{ width: 500, height: 450 }}>
      <ImageListItem key='Subheader' cols={2}>
        <ListSubheader component='div'>December</ListSubheader>
      </ImageListItem>
      {content}
    </ImageList>
  )
}

ShowEvidence.propTypes = {
  rental_id: PropTypes.string.isRequired,
}
