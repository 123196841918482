import { Grid, Typography } from "@mui/material"
import AppFrame from "../../components/AppFrame/AppFrame"
// import SearchBar from "../../components/SearchBar"
import { useVehiclesGetPublicListQuery } from "../../redux/apis/vehicles"
import { VehiclesList } from "../../components/Vehicles"
// import { useDispatch } from "react-redux"

const Welcome = () => {
  // const dispatch = useDispatch()
  // const [wordKey, setWordKey] = useState("")
  // const [filterElements, setFilterElements] = useState()
  // const { data, isLoading, isSuccess, isError, error } =
  //   useVehiclesGetPublicListQuery()

  // const handleSearch = (e) => {
  //   setWordKey(e.target.value)
  //   setFilterElements(
  //     e.target.value.length > 0
  //       ? filterElements.filter((element) =>
  //           element.properties.brand.includes(e.target.value)
  //         )
  //       : filterElements
  //   )
  //   console.log(filterElements)
  // }

  return (
    <AppFrame title='Renta de carros'>
      <Grid container spacing={1} sx={{ mb: 2 }}>
        <Grid item>
          <Typography variant='h4' sx={{ color: "#e17055" }}>
            Los mejores carros,
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='h4' sx={{ color: "GrayText" }}>
            {" "}
            con el mejor trato y servicio
          </Typography>
        </Grid>
      </Grid>
      {/* <SearchBar wordKey={wordKey} handleSearch={handleSearch} /> */}

      <VehiclesList hookQuery={useVehiclesGetPublicListQuery} />
    </AppFrame>
  )
}

export default Welcome
