import { CircularProgress, Grid, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useGetDocumentsPhotosTypesQuery } from "../../redux/apis/documents"
import {
  selectRegisterValues,
  addRegisterValues,
} from "../../redux/slices/vehicles"
import TakePicture from "../TakePicture"

export const Photos = () => {
  const dispatch = useDispatch()
  const values = useSelector(selectRegisterValues)
  const { data, isLoading, isSuccess, isError, error } =
    useGetDocumentsPhotosTypesQuery()

  const handleTakePicture = ({ name, showName, base64 }) => {
    if (name === "circulation_card") {
      return dispatch(
        addRegisterValues({
          ...values,
          documents: { ...values.documents, [name]: base64 },
        })
      )
    }

    return dispatch(
      addRegisterValues({
        ...values,
        properties: {
          ...values.properties,
          photos: {
            ...values.properties.photos,
            [name]: { label: showName, img: base64 },
          },
        },
      })
    )
  }

  const handleDateChange = (event) => {
    const target = event.target
    const { name, value } = target
    dispatch(
      addRegisterValues({
        ...values,
        documents: { ...values.documents, [name]: value },
      })
    )
  }

  let content
  if (isLoading) {
    content = <CircularProgress size={60} />
  } else if (isSuccess && data?.op && data?.photos.length > 0) {
    content = data.photos.map((photo) => (
      <Grid item key={photo.id}>
        <TakePicture
          name={photo.name}
          showName={photo.addon}
          cameraWidth={640 / 2}
          cameraHeight={480 / 2}
          handleTakePicture={handleTakePicture}
        />
      </Grid>
    ))
  } else if (isError) {
    console.log(error)
    content = <Typography>{error.data}</Typography>
  }

  return (
    <Grid item container spacing={1} sx={{ mt: 2 }}>
      <Grid item>
        <TakePicture
          name='circulation_card'
          showName='Tarjeta de circulación'
          cameraWidth={640 / 2}
          cameraHeight={480 / 2}
          handleTakePicture={handleTakePicture}
        />
        <TextField
          fullWidth
          sx={{ marginTop: "1rem" }}
          name='circulation_card_expiration'
          label='Fecha de expiración'
          type='date'
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: new Date().toISOString().split("T")[0],
          }}
          onChange={handleDateChange}
        />
      </Grid>

      {content}
    </Grid>
  )
}
