import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useGetAddressesByUserQuery } from "../../redux/apis/addresses"
import {
  handleAddress,
  selectAddresses,
  selectAddress,
} from "../../redux/slices/addresses"
import { AddAddress } from "./AddAddress"
import { gridCenter } from "../../styles/theme"

export const SelectAddress = () => {
  const dispatch = useDispatch()
  const { data, isLoading, isSuccess, isError, error } =
    useGetAddressesByUserQuery()
  const addresses = useSelector(selectAddresses)
  const address = useSelector(selectAddress)

  let content = (
    <MenuItem key="empty" disabled value="">
      <em>No se encontró alguna dirección disponibles</em>
    </MenuItem>
  )

  if (isLoading) {
    content = (
      <MenuItem key="empty" disabled value="">
        <em>Cargando...</em>
      </MenuItem>
    )
  } else if (isSuccess) {
    if (data?.op && data?.addresses && addresses.length) {
      content = addresses.map((address) => (
        <MenuItem key={address.id} value={address.id}>
          {address.street}
        </MenuItem>
      ))
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={10} md={10}>
        <FormControl fullWidth required variant="filled" error={isError}>
          <InputLabel id="address-label">Dirección </InputLabel>
          <Select
            labelId="address-label"
            id="address"
            label="Dirección"
            value={address || ""}
            onChange={(e) => {
              dispatch(handleAddress(e.target.value))
            }}
          >
            {content}
          </Select>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={2} md={2} sx={gridCenter}>
        <AddAddress />
      </Grid>
    </Grid>
  )
}
