import { createSlice } from "@reduxjs/toolkit"
import { documentsApi } from "../apis/documents"

const initialState = {
  list: [],
}

const slice = createSlice({
  name: "documentSlice",
  initialState,
  reducers: {
    setVerifyDocument: (state, { payload }) => {
      state.list.map((document) => {
        if (document.id === payload.id) {
          document.validated = payload.validated
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      documentsApi.endpoints.getDocumentsByUser.matchFulfilled,
      (state, action) => {
        const { op, documents } = action.payload
        if (op && documents) {
          state.list = documents
        }
      }
    )
  },
})

export const { setVerifyDocument } = slice.actions
export default slice.reducer

export const selectDocuments = (state) => state.document.list
