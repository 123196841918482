import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import { checkboxes } from "../../constant/vehicleData"
import { useDispatch, useSelector } from "react-redux"
import {
  selectRegisterValues,
  addRegisterValues,
} from "../../redux/slices/vehicles"

export const CheckBoxes = () => {
  const dispatch = useDispatch()
  const values = useSelector(selectRegisterValues)

  const handleCheckBoxChange = (event) => {
    const target = event.target
    const { name, checked } = target

    dispatch(
      addRegisterValues({
        ...values,
        properties: {
          ...values.properties,
          [name]: checked,
        },
      })
    )
  }

  return (
    <Grid item container spacing={1} sx={{ mt: 2 }}>
      {checkboxes.map((checkbox) => (
        <Grid item key={checkbox.name}>
          <FormControlLabel
            label={checkbox.label}
            control={
              <Checkbox
                name={checkbox.name}
                checked={values.properties[checkbox.name] || false}
                onChange={handleCheckBoxChange}
              />
            }
          />
        </Grid>
      ))}
    </Grid>
  )
}
