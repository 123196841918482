import { Vehicles } from "./Vehicles"
import { Box, CircularProgress, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectVehicles } from "../../redux/slices/vehicles"
import { useEffect } from "react"
import { addError } from "../../redux/slices/errors"
import PropTypes from "prop-types"

export const VehiclesList = ({ hookQuery }) => {
  const dispatch = useDispatch()
  const { data, isLoading, isSuccess, isError, error } = hookQuery()
  const vehicles = useSelector(selectVehicles)

  useEffect(() => {
    if (isError) {
      dispatch(
        addError({
          kind: "fetch_error",
          severity: "error",
          message: error.data.message,
        })
      )
    }
  }, [isError, error, dispatch])

  let content
  if (isLoading) {
    content = (
      <Box>
        <CircularProgress size={60} />
      </Box>
    )
  } else if (isSuccess && data.op && vehicles.length) {
    content = (
      <Box
        component='div'
        sx={{
          width: 1,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
          gap: 2,
        }}
      >
        {vehicles.map((vehicle) => (
          <Vehicles key={vehicle.id} data={vehicle} />
        ))}
      </Box>
    )
  } else {
    content = (
      <Typography variant='body1'>No hay ningún vehículo registrado</Typography>
    )
  }

  return <Box>{content}</Box>
}

VehiclesList.propTypes = {
  hookQuery: PropTypes.func.isRequired,
}
