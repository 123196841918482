import {
  Box,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material"
import { ShowDocuments } from "../General"
import PropTypes from "prop-types"
import {
  useGetViewDocumentQuery,
  useVehicleValidateDocumentMutation,
} from "../../redux/apis/vehicles"
import { red } from "@mui/material/colors"
import { useDispatch, useSelector } from "react-redux"
import { selectPermissionsByPath } from "../../redux/slices/auth"
import { checkDaysToExpiration, formatDate } from "../../utils/handleData"
import { ShowDateValidate } from "./ShowDateValidate"
import { selectDocuments, setVerifyDocument } from "../../redux/slices/vehicles"

export const TableDocuments = ({ vehicleID }) => {
  const dispatch = useDispatch()
  const documents = useSelector(selectDocuments)
  const permissions = useSelector(() =>
    selectPermissionsByPath("/vehicles-revision/verify")
  )()
  const [vehicleValidateDocument, { isLoading }] =
    useVehicleValidateDocumentMutation()

  const handleChange = (document) => (event) => {
    const { checked } = event.target
    vehicleValidateDocument({ vehicleID, document, validate: checked }).then(
      ({ data, error }) => {
        if (data.op) {
          console.log("validado-dispatch", data)
          dispatch(setVerifyDocument(data.document))
        }

        if (error) {
          console.log(error)
        }
      }
    )
  }

  const handleDateExpired = (document) => {
    const aux = checkDaysToExpiration(document.expires_at)
    const formatAux = formatDate(document.expires_at)

    return (
      <>
        <TableCell align='right'>
          <ShowDateValidate days={aux} formate={formatAux} />
        </TableCell>
        {aux.valid && (
          <TableCell align='right'>
            <FormControlLabel
              control={
                <Switch
                  checked={document.validated}
                  onChange={handleChange(document)}
                  disabled={isLoading}
                />
              }
              label={document.validated ? "Validado" : "No validado"}
            />
          </TableCell>
        )}
      </>
    )
  }

  return (
    <Box>
      <Typography variant='h6' gutterBottom>
        Documentos
      </Typography>
      <TableContainer>
        <Table aria-label='simple table'>
          <TableBody>
            {documents.map((document) => (
              <TableRow
                key={document.id}
                sx={{
                  backgroundColor: document.validated ? "inherit" : red[100],
                }}
              >
                <TableCell component='th' scope='row'>
                  {document.label}
                </TableCell>
                {permissions.can_update && handleDateExpired(document)}
                <TableCell align='right'>
                  <ShowDocuments
                    title={document.label}
                    path={document.img}
                    hook={useGetViewDocumentQuery}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

TableDocuments.propTypes = {
  vehicleID: PropTypes.string.isRequired,
}
