import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "../../utils/headersApi"

export const usersApi = createApi({
  reducerPath: "userApi",
  baseQuery,
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (post) => ({
        url: `users/sign-up/${post.type}`,
        method: "POST",
        body: post,
      }),
    }),
    getUsers: builder.query({
      query: () => ({
        url: "/users",
      }),
    }),
    getUser: builder.query({
      query: (id) => ({
        url: `/users/${id}`,
      }),
    }),
    setUserValidate: builder.mutation({
      query: (post) => ({
        url: `users/validate`,
        method: "POST",
        body: post,
      }),
    }),
    getViewDocument: builder.query({
      query: (path) => ({
        url: path,
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response) => {
        return URL.createObjectURL(response)
      },
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useRegisterUserMutation,
  useGetViewDocumentQuery,
  useSetUserValidateMutation,
} = usersApi
