import { createSlice } from "@reduxjs/toolkit";
import { creditCardsApi } from "../apis/credit_cards";

const initialState = {
	cards: [],
	openModal: false,
	card: "",
};

const slice = createSlice({
	name: "creditCards",
	initialState,
	reducers: {
		clearCard: () => {
			return initialState;
		},
		handleModal: (state, { payload }) => {
			state.openModal = payload;
		},
		handleCards: (state, { payload }) => {
			if (Array.isArray(payload)) {
				state.cards = payload;
			} else if (payload !== null) {
				state.cards.push(payload);
			}
		},
		setCard: (state, { payload }) => {
			state.card = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			creditCardsApi.endpoints.cardGetByUser.matchFulfilled,
			(state, action) => {
				const { op, cards } = action.payload;
				if (op && cards) {
					state.cards = cards;
				}
			},
		);
	},
});

export const { clearCard, handleModal, handleCards, setCard } = slice.actions;
export default slice.reducer;

export const selectOpenModal = (state) => state.creditCard.openModal;
export const selectCards = (state) => state.creditCard.cards;
export const selectCard = (state) => state.creditCard.card;
