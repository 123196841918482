import { fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BASE_URI } from "../constant/uris"

// in this api is use RTK toolkit from redux-toolkit
export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URI,
  prepareHeaders: (headers, { getState }) => {
    const token =
      getState().auth?.user?.token ||
      JSON.parse(localStorage.getItem("user"))?.token

    if (token) {
      headers.set("Authorization", `Bearer ${token}`)
    }
    return headers
  },
})

// import retry from "@reduxjs/toolkit/query/react" if you want use retry
// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 })

export const baseQueryNoHeaders = fetchBaseQuery({
  baseUrl: BASE_URI,
})
