import AppFrame from "../../components/AppFrame"
import { AddCard } from "../../components/CreditCard"
import ListCards from "../../components/CreditCard/ListCards"
import ErrorList from "../../components/ErrorList"

const CardsView = () => {
  return (
    <AppFrame title='Tarjetas'>
      <ErrorList />
      <AddCard />
      <ListCards />
    </AppFrame>
  )
}

export default CardsView
