import { createSlice } from "@reduxjs/toolkit"
import { vehiclesApi } from "../apis/vehicles"

const slice = createSlice({
  name: "vehiclesSlice",
  initialState: {
    list: [],
    documents: [],
    brands: [],
    models: [],
    types: [],
    registerValues: { properties: {} },
    verifyVehicle: null,
    vehicleToRental: null,
    openModal: false,
    rentals: [],
  },
  reducers: {
    handleModalVehicles: (state, { payload }) => {
      state.openModal = payload
    },
    handleVehicles: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.vehicles = payload
      } else if (payload !== null) {
        state.vehicles.push(payload)
      }
    },
    getVerifyVehicle: (state, { payload }) => {
      localStorage.setItem("verifyVehicle", JSON.stringify(payload))
      state.verifyVehicle = payload
    },
    setVerifyVehicle: (state, { payload }) => {
      state.list.map((vehicle) => {
        if (vehicle.id === payload.id) {
          vehicle.available = payload.available
          vehicle.validated = payload.validated
          return vehicle
        }
      })
    },
    setVerifyDocument: (state, { payload }) => {
      state.verifyVehicle ??= JSON.parse(localStorage.getItem("verifyVehicle"))
      state.verifyVehicle.documents.map((document) => {
        if (document.label === payload.label) {
          document.validated = payload.validated
        }
      })
    },
    addRegisterValues: (state, { payload }) => {
      state.registerValues = payload
    },
    setVehicleToRental: (state, { payload }) => {
      localStorage.setItem("vehicleToRental", JSON.stringify(payload))
      state.vehicleToRental = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        vehiclesApi.endpoints.vehiclesAdd.matchFulfilled,
        (state, action) => {
          const { op, vehicle } = action.payload
          if (op && vehicle) {
            state.list.push(vehicle)
          }
        }
      )
      .addMatcher(
        vehiclesApi.endpoints.vehiclesGetByUser.matchFulfilled,
        (state, action) => {
          const { op, vehicles } = action.payload
          if (op && vehicles?.length > 0) {
            state.list = vehicles
          }
        }
      )
      .addMatcher(
        vehiclesApi.endpoints.vehiclesGetList.matchFulfilled,
        (state, action) => {
          const { op, vehicles } = action.payload
          if (op && vehicles?.length > 0) {
            state.list = vehicles
          }
        }
      )
      .addMatcher(
        vehiclesApi.endpoints.vehicleGetBrandList.matchFulfilled,
        (state, action) => {
          const { op, brands } = action.payload
          if (op && brands?.length > 0) {
            state.brands = brands
          }
        }
      )
      .addMatcher(
        vehiclesApi.endpoints.vehicleGetModelList.matchFulfilled,
        (state, action) => {
          const { op, models } = action.payload
          if (op && models?.length > 0) {
            state.models = models
          } else if (op && !models) {
            state.models = []
          }
        }
      )
      .addMatcher(
        vehiclesApi.endpoints.vehicleGetTypeList.matchFulfilled,
        (state, action) => {
          const { op, types } = action.payload
          if (op && types?.length > 0) {
            state.types = types
          }
        }
      )
      .addMatcher(
        vehiclesApi.endpoints.vehiclesGetPublicList.matchFulfilled,
        (state, action) => {
          const { op, vehicles } = action.payload
          if (op && vehicles?.length > 0) {
            state.list = vehicles
          }
        }
      )
      .addMatcher(
        vehiclesApi.endpoints.vehicleRental.matchFulfilled,
        (state, action) => {
          const { op, rental } = action.payload
          state.rentals.push(rental)
          if (op && rental) {
            state.list.map((item) => {
              if (item.id === rental.vehicle) {
                item.available = false
              }
            })
          }
        }
      )
  },
})

export const {
  handleModalVehicles,
  handleVehicles,
  getVerifyVehicle,
  setVerifyVehicle,
  setVerifyDocument,
  addRegisterValues,
  setVehicleToRental,
} = slice.actions

export default slice.reducer

export const selectOpenModalVehicles = (state) => state.vehicles.openModal
export const selectVehicles = (state) => {
  const list = state.vehicles.list.slice()
  return list.reverse()
}
export const selectVerifyVehicle = (state) =>
  state.vehicles.verifyVehicle ||
  JSON.parse(localStorage.getItem("verifyVehicle"))
export const selectDocuments = (state) =>
  state.vehicles.verifyVehicle?.documents ||
  JSON.parse(localStorage.getItem("verifyVehicle")).documents
export const selectBrands = (state) => state.vehicles.brands
export const selectModels = (state) => state.vehicles.models
export const selectTypes = (state) => state.vehicles.types
export const selectRegisterValues = (state) => state.vehicles.registerValues
export const selectVehicleToRental = (state) =>
  state.vehicleToRental || JSON.parse(localStorage.getItem("vehicleToRental"))
