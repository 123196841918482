import { memo, useState } from "react"
import { Box, CssBaseline, ThemeProvider, Toolbar } from "@mui/material"
import TopBar from "./TopBar"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { selectIsAuth } from "../../redux/slices/auth"
import SideBar from "./SideBar"
import { dynFirst } from "../../styles/theme"

const AppFrame = ({ title, children }) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [drawerWidth] = useState(240)
  const isAuth = useSelector(selectIsAuth)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <ThemeProvider theme={dynFirst}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* ######################## TOP BAR START ######################## */}
        <TopBar
          title={title}
          isAuth={isAuth}
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
        />
        {/* ######################## TOP BAR END ######################## */}
        <SideBar
          isAuth={isAuth}
          mobileOpen={mobileOpen}
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
        />
        {/* ######################## CONTENT START ######################## */}
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${isAuth ? drawerWidth : 0}px)` },
          }}
        >
          <Toolbar />
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

AppFrame.propTypes = {
  title: PropTypes.string.isRequired,
  window: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default memo(AppFrame)
