import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "../../utils/headersApi"

export const documentsApi = createApi({
  reducerPath: "documentsApi",
  baseQuery,
  endpoints: (builder) => ({
    getDocumentsByUser: builder.query({
      query: (userId) => ({
        url: `documents/${userId}`,
      }),
    }),
    getDocumentsPhotosTypes: builder.query({
      query: () => ({
        url: "documents/photos/types",
      }),
    }),
  }),
})

export const { useGetDocumentsByUserQuery, useGetDocumentsPhotosTypesQuery } =
  documentsApi
