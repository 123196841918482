import { useSelector } from "react-redux"
import { selectRental } from "../../redux/slices/rentals"
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TextareaAutosize,
  Typography,
} from "@mui/material"
import { BASE_URI } from "../../constant/uris"
import {
  useEndRentalMutation,
  useStartRentalMutation,
} from "../../redux/apis/rentals"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { RENTAL_STATUS, USER_ROLE } from "../../constant/status"
import { EvidencesList } from "../Evidence/EvidencesList"
import { useTheme } from "@mui/system"
import { selectEvidences } from "../../redux/slices/evidences"
import { gridCenter } from "../../styles/theme"
import { selectUser } from "../../redux/slices/auth"
import { commentStyles } from "../../styles/comments"
import { Comment } from "../Comment/Comment"
import { AddComment } from "../Comment/AddComment"
import ShowEvidence from "../Evidence/ShowEvidences"

export const RentalDetails = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const rental = useSelector(selectRental)
  const evidences = useSelector(selectEvidences)
  const [problem, setProblem] = useState(false)
  const [color, setColor] = useState("success")
  const [comment, setComment] = useState("")
  const [startRental, { isLoading: isLoadingStartRental }] =
    useStartRentalMutation()
  const [endRental, { isLoading: isLoadingEndRental }] = useEndRentalMutation()
  const user = useSelector(selectUser)

  console.log("RentalDetails", rental)

  const handleSwitchProblem = () => {
    setProblem(!problem)
    setColor(problem ? "success" : "warning")
  }

  const handleStartRental = () => {
    startRental(rental).then((res) => {
      const { op } = res.data
      if (op) {
        console.log("Renta iniciada")
      }
    })
  }

  const handleFinishRental = () => {
    if (problem && (comment === "" || evidences.length === 0)) {
      return alert("Debes agregar un comentario y evidencias")
    }

    endRental({
      id: rental.id,
      vehicle_id: rental.vehicle.id,
      problem,
      evidences,
      comment,
    }).then((res) => {
      const { op } = res.data
      if (op) {
        console.log("Renta finalizada")
        navigate(-1)
      }
    })
  }

  const handleCommentChange = (event) => {
    setComment(event.target.value)
  }

  const createData = (name, value) => {
    return { name, value }
  }

  const rows = [
    createData("Nombre", rental.client.first_name),
    createData("Correo", rental.client.email),
    createData("Teléfono", rental.client.phone),
    createData("Fecha de renta", rental.start),
    createData("Fecha de entrega", rental.end),
    createData("Costo", rental.collect),
    createData("Placas", rental.vehicle.plates),
  ]

  const showAddComment = () => {
    return (
      (user.role === USER_ROLE.PARTNER && !rental.comment_partner) ||
      (user.role === USER_ROLE.CLIENT && !rental.comment_client)
    )
  }

  return (
    <Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar
              src={`${BASE_URI + rental.client.avatar}`}
              sx={{ width: 120, height: 120 }}
              variant='rounded'
            />
          </Grid>
          <Grid item>
            <Avatar
              src={`${BASE_URI + rental.vehicle.photos.FRONT.img}`}
              sx={{ width: 120, height: 120 }}
              variant='rounded'
            />
          </Grid>
        </Grid>
      </Box>
      {/*
      =======================
      DATA OF RENTAL
      =======================
      */}
      <Box sx={{ m: 2 }}>
        <TableContainer component={Paper}>
          <Table aria-label='Detalles de la renta'>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    component='th'
                    scope='row'
                    sx={{ bgcolor: theme.palette.primary.main }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align='center' sx={{ width: 1 }}>
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/*
      =======================
        TOOLS OF RENTAL
      =======================
      */}
      <Box sx={{ margin: 2 }}>
        {user.role === USER_ROLE.PARTNER && (
          <Box>
            {rental.status.name === RENTAL_STATUS.WAITING && (
              <Button
                variant='contained'
                color='info'
                onClick={handleStartRental}
                disabled={isLoadingStartRental}
              >
                Iniciar
              </Button>
            )}
            {rental.status.name === RENTAL_STATUS.RENTED && (
              <Box sx={gridCenter}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant='contained'
                      color={color}
                      onClick={handleFinishRental}
                      disabled={isLoadingEndRental}
                    >
                      Finalizar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Switch color='warning' onChange={handleSwitchProblem} />{" "}
                    Reportar problema
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        )}
        {/* 
        =======================
          SHOW EVIDENCES
        =======================
        */}
        {rental.status.name === RENTAL_STATUS.FINISHED_WITH_PENALTY && (
          <ShowEvidence rental_id={rental.id} />
        )}
        {/* 
        =======================
          COMMENTS OF RENTAL
        =======================
        */}
        {(rental.status.name === RENTAL_STATUS.FINISHED ||
          rental.status.name === RENTAL_STATUS.FINISHED_WITH_PENALTY) && (
          <>
            <Box sx={commentStyles.container}>
              <Typography variant='h5'>Comentarios</Typography>
              {rental.comment_partner && (
                <Comment
                  author={rental.partner.first_name}
                  text={rental.comment_partner}
                />
              )}
              {rental.comment_client && (
                <Comment
                  author={rental.client.first_name}
                  text={rental.comment_client}
                />
              )}
            </Box>

            {showAddComment() && <AddComment rental_id={rental.id} />}
          </>
        )}
        {/*
        =======================
          EVIDENCES OF RENTAL
        =======================
        */}
        {problem && <EvidencesList />}
      </Box>
      {rental.status.name === "rented" ? (
        <Box sx={{ margin: 2 }}>
          <TextareaAutosize
            aria-label='description'
            placeholder='Comentarios'
            style={{ width: "100%" }}
            minRows={5}
            onChange={handleCommentChange}
            value={comment}
          />
        </Box>
      ) : null}
    </Box>
  )
}
