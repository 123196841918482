import { Box, Button, Icon, Modal } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { modalStyle } from "../../styles/modal"
import { AddressRegister } from "./AddressRegister"
import { handleModal, selectModal } from "../../redux/slices/helpers"

export const AddAddress = () => {
  const dispatch = useDispatch()
  const open = useSelector(selectModal)
  const handleOpen = () => dispatch(handleModal(true))
  const handleClose = () => dispatch(handleModal(false))

  return (
    <>
      <Button startIcon={<Icon>add_box</Icon>} onClick={handleOpen}>
        Dirección
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Dirección"
        aria-describedby="Dirección donde se puede recoger el vehículo"
      >
        <Box sx={modalStyle}>
          <AddressRegister />
        </Box>
      </Modal>
    </>
  )
}
