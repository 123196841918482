import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  modal: false,
}

const slice = createSlice({
  name: "helpersSlice",
  initialState,
  reducers: {
    handleModal: (state, { payload }) => {
      state.modal = payload
    },
  },
})

export const { handleModal } = slice.actions
export default slice.reducer

export const selectModal = (state) => state.helpers.modal
