export const selects = [
  { name: "brand", label: "Marca" },
  { name: "model", label: "Modelo" },
  { name: "type", label: "Tipo de carro" },
]

export const inputs = [
  { name: "price", label: "Precio", type: "number" },
]

export const inputsDirect = [{ name: "plates", label: "Placas", type: "text" }]

export const checkboxes = [
  { name: "automatic", label: "Automático" },
  { name: "electric_mirrors", label: "Retrovisores Eléctricos" },
  { name: "air_conditioning", label: "Aire Acondicionado" },
  { name: "fog_lamps", label: "Faros de niebla" },
  {
    name: "electro_assisted_hydraulic",
    label: "Dirección hidráulica electro asistida",
  },
  { name: "air_bags", label: "Bolsas de aire" },
  { name: "abs", label: "Frenos ABS" },
  {
    name: "controls_in_wheel",
    label: "Controles de audio y teléfono en el volante",
  },
  { name: "screen", label: "Pantalla" },
  { name: "carPlay_androidAuto", label: "Apple CarPlay™ y Android Auto™" },
]
