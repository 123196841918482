import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import PropTypes from "prop-types"
import { getLast10Years } from "../../utils/handleData"

export const SelectYears = ({ selected, handleSelectChange }) => {
  const years = getLast10Years()

  return (
    <FormControl required variant='filled' fullWidth>
      <InputLabel id={`year-label`}>Año</InputLabel>
      <Select
        labelId='year-label'
        name='year'
        label='Año'
        value={selected || ""}
        onChange={handleSelectChange}
      >
        {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

SelectYears.propTypes = {
  selected: PropTypes.string,
  handleSelectChange: PropTypes.func.isRequired,
}
