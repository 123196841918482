import {
  Button,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { green, red } from "@mui/material/colors"
import { useGetUsersQuery } from "../../redux/apis/users"
import { useSelector, useDispatch } from "react-redux"
import { selectPermissionsByPath } from "../../redux/slices/auth"
import CircularProgress from "@mui/material/CircularProgress"
import { getVerifyUser } from "../../redux/slices/users"
import { useNavigate } from "react-router-dom"
import { selectUsersList } from "../../redux/slices/users"

export const UsersList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data, isLoading, isSuccess, isError, error } = useGetUsersQuery()
  const permissions = useSelector(() => selectPermissionsByPath("/users"))()
  const users = useSelector(selectUsersList)

  const handleVerify = (user) => {
    dispatch(getVerifyUser(user))
    navigate("/users/verify")
  }

  // TODO add alert to errors
  let content
  if (isLoading) {
    content = <CircularProgress size={60} />
  } else if (isError) {
    content = <p>{error.error}</p>
  } else if (isSuccess && data?.op) {
    content = (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='right'>Nombre</TableCell>
              <TableCell align='right'>Correo</TableCell>
              <TableCell align='right'>Teléfono</TableCell>
              <TableCell align='right'>Ciudad</TableCell>
              <TableCell align='right'>Código Postal</TableCell>
              <TableCell align='right'>Tipo de usuario</TableCell>
              <TableCell align='right'>Verificado</TableCell>
              <TableCell align='right'>Herramientas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                key={user.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor: !user.validated && red[100],
                }}
              >
                <TableCell align='right'>{`${user.first_name} ${user.last_name}`}</TableCell>
                <TableCell align='right'>{user.email}</TableCell>
                <TableCell align='right'>{user.phone}</TableCell>
                <TableCell align='right'>{user.address.region.city}</TableCell>
                <TableCell align='right'>{user.address.zip}</TableCell>
                <TableCell align='right'>{user.role}</TableCell>
                <TableCell align='right'>
                  {user.validated ? (
                    <Icon
                      baseClassName='material-icons'
                      sx={{ color: green[500] }}
                    >
                      done
                    </Icon>
                  ) : (
                    <Icon
                      baseClassName='material-icons'
                      sx={{ color: red[500] }}
                    >
                      close
                    </Icon>
                  )}
                </TableCell>
                <TableCell align='right'>
                  {permissions.can_update && (
                    <Button
                      variant='contained'
                      endIcon={
                        <Icon baseClassName='material-icons'>account_box</Icon>
                      }
                      onClick={() => handleVerify(user)}
                    >
                      Revisar
                    </Button>
                  )}
                  {permissions.can_delete && (
                    <Button>
                      <Icon sx={{ color: red[500] }}>delete</Icon>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return <>{content}</>
}
