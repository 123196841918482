export const commentStyles = {
    container: {
        marginTop: 4,
    },
    header: {
        fontSize: 16,
        fontWeight: "bold",
        padding: 1,
        marginTop: 4,
        backgroundColor: "primary.light",
    },
    text: {
        fontSize: 14,
        lineHeight: 1.5,
        padding: 2,
    },
    date: {
        fontSize: 12,
        color: "gray",
    },
}