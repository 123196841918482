import {
	Box,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material";
import { useCardGetByUserQuery } from "../../redux/apis/credit_cards";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { addError } from "../../redux/slices/errors";
import { useEffect } from "react";
import {
	selectCards,
	selectCard,
	setCard,
} from "../../redux/slices/credit_cards";
import { AddCard } from "./AddCard";
import PropTypes from "prop-types";
import { gridCenter } from "../../styles/theme";

export const SelectCards = ({ style = "standard" }) => {
	const dispatch = useDispatch();
	const { data, isLoading, isSuccess, isError, error } =
		useCardGetByUserQuery();
	const cards = useSelector(selectCards);
	const card = useSelector(selectCard);

	useEffect(() => {
		if (isError) {
			dispatch(
				addError({
					kind: "fetch_error",
					severity: "error",
					message: error.data.message,
				}),
			);
		}
	}, [error, isError]);

	let content;
	if (isLoading) {
		content = <CircularProgress size={60} />;
	} else if (isSuccess && data.op) {
		content = cards.map((card) => (
			<MenuItem key={card.id} value={card.id}>
				{card.name} - {card.number}
			</MenuItem>
		));
	}

	const handleChange = (event) => {
		const { value } = event.target;
		dispatch(setCard(value));
	};

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={10} md={10}>
					<FormControl variant={style} fullWidth>
						<InputLabel id='credit-cards-label'>Tarjetas</InputLabel>
						<Select
							labelId='credit-cards-label'
							id='credit-cards'
							value={card || ""}
							label='Tarjetas'
							onChange={handleChange}
						>
							{content}
						</Select>
					</FormControl>
				</Grid>
				<Grid item md={2} mt={1} sx={gridCenter}>
					<AddCard />
				</Grid>
			</Grid>
		</Box>
	);
};

SelectCards.propTypes = {
	style: PropTypes.string,
};
