import { createSlice } from "@reduxjs/toolkit"

const slice = createSlice({
  name: "errors",
  initialState: {
    errors: [],
  },
  reducers: {
    addError: (state, action) => {
      const { payload } = action
      if (Array.isArray(payload)) {
        state.errors = action.payload
      } else {
        const { kind, message } = payload
        if (state.errors.find((error) => error.kind === kind)) {
          state.errors = state.errors.map((error) => {
            if (error.kind === kind) {
              error.message = message
            }
            return error
          })
        } else {
          state.errors.push(payload)
        }
      }
    },
    removeError: (state, action) => {
      state.errors = state.errors.filter(
        (error) => error.kind !== action.payload
      )
    },
  },
})

export const { addError, removeError } = slice.actions
export default slice.reducer

export const selectErrors = (state) => state.errors.errors
