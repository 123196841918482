import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import { selects } from "../../constant/vehicleData"
import PropTypes from "prop-types"
import {
  useVehicleGetBrandListQuery,
  useVehicleGetModelListMutation,
  useVehicleGetTypeListMutation,
} from "../../redux/apis/vehicles"
import {
  selectBrands,
  selectModels,
  selectTypes,
  addRegisterValues,
  selectRegisterValues,
} from "../../redux/slices/vehicles"
import { memo } from "react"

const CustomSelect = ({
  isLoading,
  isSuccess,
  noLoadMessage,
  name,
  label,
  selected,
  options,
  handleSelectChange,
}) => {
  return (
    <FormControl required variant='filled' disabled={!isSuccess} fullWidth>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        name={name}
        label={label}
        value={selected || ""}
        onChange={handleSelectChange}
      >
        {isLoading ? (
          <MenuItem value='' selected>
            {noLoadMessage ? noLoadMessage : "Cargando..."}
          </MenuItem>
        ) : isSuccess ? (
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))
        ) : null}
      </Select>
      {!isSuccess && noLoadMessage ? (
        <FormHelperText>{noLoadMessage}</FormHelperText>
      ) : null}
    </FormControl>
  )
}

CustomSelect.propTypes = {
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  noLoadMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      addon: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
}

const CustomSelects = () => {
  const dispatch = useDispatch()
  const values = useSelector(selectRegisterValues)
  const { isLoading: isLoadingBrands, isSuccess: isSuccessBrands } =
    useVehicleGetBrandListQuery()
  const [
    vehicleGetModelList,
    { isLoading: isLoadingModels, isSuccess: isSuccessModels },
  ] = useVehicleGetModelListMutation()
  const [
    vehicleGetTypeList,
    { isLoading: isLoadingTypes, isSuccess: isSuccessTypes },
  ] = useVehicleGetTypeListMutation()

  const brands = useSelector(selectBrands)
  const models = useSelector(selectModels)
  const types = useSelector(selectTypes)

  const handleSelectChange = (event) => {
    const { name, value } = event.target

    if (name === "brand") {
      vehicleGetModelList(value)

      dispatch(
        addRegisterValues({
          ...values,
          properties: {
            ...values.properties,
            brand: value,
            model: "",
          },
        })
      )
    } else if (name === "model") {
      vehicleGetTypeList(value)

      dispatch(
        addRegisterValues({
          ...values,
          properties: {
            ...values.properties,
            model: value,
            type: "",
          },
        })
      )
    } else {
      dispatch(
        addRegisterValues({
          ...values,
          properties: {
            ...values.properties,
            [name]: value,
          },
        })
      )
    }
  }

  return selects.map((select) => {
    switch (select.name) {
      case "brand":
        return (
          <CustomSelect
            key={select.name}
            isLoading={isLoadingBrands}
            isSuccess={isSuccessBrands}
            name={select.name}
            label={select.label}
            selected={values.properties[select.name]}
            options={brands}
            handleSelectChange={handleSelectChange}
          />
        )
      case "model":
        return (
          <CustomSelect
            key={select.name}
            isLoading={isLoadingModels}
            isSuccess={isSuccessModels}
            noLoadMessage=' -- Seleccione una marca --'
            name={select.name}
            label={select.label}
            selected={values.properties[select.name]}
            options={models}
            handleSelectChange={handleSelectChange}
          />
        )
      case "type":
        return (
          <CustomSelect
            key={select.name}
            isLoading={isLoadingTypes}
            isSuccess={isSuccessTypes}
            noLoadMessage=' -- Seleccione un modelo --'
            name={select.name}
            label={select.label}
            selected={values.properties[select.name]}
            options={types}
            handleSelectChange={handleSelectChange}
          />
        )
    }
  })
}

export default memo(CustomSelects)
