export const RENTAL_STATUS = Object.freeze({
  WAITING: "waiting",
  RENTED: "rented",
  FINISHED: "finished",
  FINISHED_WITH_PENALTY: "finished_with_penalty",
})

export const USER_ROLE = Object.freeze({
  CLIENT: "Cliente",
  PARTNER: "Socio",
})