import { Box, Button, TextareaAutosize } from "@mui/material"
import PropTypes from "prop-types"
import { useState } from "react"
import { useCommentRentalMutation } from "../../redux/apis/rentals"
import { useDispatch } from "react-redux"
import { setComment as handleSetComment } from "../../redux/slices/rentals"

export const AddComment = ({ rental_id }) => {
  const [comment, setComment] = useState("")
  const [commentRental, { isLoading }] = useCommentRentalMutation()
  const dispatch = useDispatch()

  const handleChange = (e) => {
    setComment(e.target.value)
  }

  const handleCommentRental = () => {
    if (!comment) {
      alert("Debes agregar un comentario")
      return
    }
    commentRental({ rental_id, comment }).then((res) => {
      const { op, rental } = res.data
      if (op) {
        dispatch(handleSetComment(rental))
      }
    })
  }

  return (
    <Box sx={{ m: 2 }}>
      <TextareaAutosize
        aria-label='agregar comentario'
        placeholder='Agrega un comentario'
        minRows={10}
        style={{ width: "100%", borderRadius: 5 }}
        value={comment}
        onChange={handleChange}
      />
      <Button
        variant='contained'
        color='success'
        onClick={handleCommentRental}
        disable={isLoading.toString()}
      >
        Agregar
      </Button>
    </Box>
  )
}

AddComment.propTypes = {
  rental_id: PropTypes.string.isRequired,
}
