import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useRolesListQuery } from "../../redux/apis/information/roles"

const SelectRole = ({ role_id, handleChange }) => {
  const [options, setOptions] = useState()
  const [error, setError] = useState("")
  const { data, isLoading, isSuccess, isError } = useRolesListQuery()

  useEffect(() => {
    if (isLoading) {
      setOptions(
        <MenuItem key="empty" disabled value="">
          <em>Cargando...</em>
        </MenuItem>
      )
    } else if (isSuccess) {
      if (data.op && data.roles) {
        setOptions(
          data.roles.map((role) => {
            return (
              <MenuItem key={role.id} value={role.id}>
                {role.addon}
              </MenuItem>
            )
          })
        )
      } else {
        setOptions(
          <MenuItem key="empty" disabled value="">
            <em>No se encontraron tipo de usuarios disponibles</em>
          </MenuItem>
        )
      }
    } else if (isError) {
      setError(
        "Error al cargar los tipos de usuarios por favor intente de nuevo mas tarde"
      )
    }
  }, [isLoading, isSuccess, isError, data])

  return (
    <FormControl required variant="filled" fullWidth error={isError}>
      <InputLabel id="role-label">Tipo de usuario </InputLabel>
      <Select
        labelId="role-label"
        id="role"
        label="Tipo de usuario"
        value={role_id ? role_id : ""}
        onChange={handleChange("role_id")}
      >
        {options}
      </Select>
      {isError ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  )
}

SelectRole.propTypes = {
  role_id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default SelectRole
