import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../utils/headersApi";

export const creditCardsApi = createApi({
	reducerPath: "creditCardsApi",
	baseQuery,
	endpoints: (builder) => ({
		cardAdd: builder.mutation({
			query: (post) => ({
				url: "credit-cards/add",
				method: "POST",
				body: post,
			}),
		}),
		cardGetByUser: builder.query({
			query: () => ({
				url: "credit-cards/get-by-user",
			}),
		}),
	}),
});

export const { useCardAddMutation, useCardGetByUserQuery } = creditCardsApi;
