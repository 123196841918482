import { createSlice } from "@reduxjs/toolkit"
import { addressesApi } from "../apis/addresses"

const initialState = {
  address: "",
  addresses: [],
  defaultAddress: {},
}

const slice = createSlice({
  name: "addressesSlice",
  initialState,
  reducers: {
    handleAddress: (state, { payload }) => {
      state.address = payload
    },
    handleNewAddress: (state, { payload }) => {
      state.addresses.push(payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        addressesApi.endpoints.getAddressesDefaultByUser.matchFulfilled,
        (state, action) => {
          const { op, address } = action.payload
          if (op && address) {
            state.defaultAddress = address
          }
        }
      )
      .addMatcher(
        addressesApi.endpoints.getAddressesByUser.matchFulfilled,
        (state, action) => {
          const { op, addresses } = action.payload
          if (op && addresses) {
            state.addresses = addresses
          }
        }
      )
  },
})

export const { handleAddress, handleNewAddress } = slice.actions
export default slice.reducer

// selectors for addressesSlice
export const selectAddress = (state) => state.addresses?.address
export const selectAddresses = (state) => state.addresses?.addresses
export const selectDefaultAddress = (state) => state.addresses?.defaultAddress
