import { useState } from "react"
import { Box, Container, Grid, Button } from "@mui/material"
import FromTenant from "./FormTenant"
import FromLessor from "./FormLessor"
import ErrorList from "../ErrorList"

const FormRegister = () => {
  const [typeUser, setTypeUser] = useState("")

  const handleTypeUser = (type) => {
    setTypeUser(type)
  }

  const SelectUserUI = () => {
    return (
      <Box>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleTypeUser("tenant")}
              value='tenant'
            >
              Quiero rentar un auto
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => handleTypeUser("lessor")}
              value='lessor'
            >
              Quiero que mi carro se alquile
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const SelectUser = () => {
    switch (typeUser) {
      case "tenant":
        return <FromTenant handleTypeUser={handleTypeUser} />
      case "lessor":
        return <FromLessor handleTypeUser={handleTypeUser} />
      default:
        return <SelectUserUI />
    }
  }

  return (
    <Container>
      <h1>Bienvenido a DYN renta de carros</h1>
      {/* =========== Errors =========== */}
      <ErrorList />
      <SelectUser />
    </Container>
  )
}

export default FormRegister
