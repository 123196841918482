export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "90%",
  width: "95%",
  bgcolor: "white",
  overflow: "auto",
  boxShadow: 24,
  p: 2,
}
