import { useState, forwardRef } from "react";
import { Box, Grid } from "@mui/material";
import { TextField, Button } from "@mui/material";
import Cards from "elt-react-credit-cards";
import { IMaskInput } from "react-imask";
import "elt-react-credit-cards/es/styles-compiled.css";
import { useDispatch } from "react-redux";
import { useCardAddMutation } from "../../redux/apis/credit_cards";
import { addError } from "../../redux/slices/errors";
import { handleModal, handleCards } from "../../redux/slices/credit_cards";
import PropTypes from "prop-types";

const FormantCardNumber = forwardRef((props, ref) => {
	const { onChange, name, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask='0000 0000 0000 0000'
			definitions={{
				"#": /\d/,
			}}
			inputRef={ref}
			onAccept={(value) => onChange({ target: { name, value } })}
			overwrite
		/>
	);
});
FormantCardNumber.displayName = "FormantCardNumber";
FormantCardNumber.propTypes = {
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
};

const ExpiryDate = forwardRef((props, ref) => {
	const { onChange, name, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask='00/00'
			definitions={{
				"#": /\d/,
			}}
			inputRef={ref}
			onAccept={(value) => onChange({ target: { name, value } })}
			overwrite
		/>
	);
});
ExpiryDate.displayName = "ExpiryDate";
ExpiryDate.propTypes = {
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
};

const CVCFormat = forwardRef((props, ref) => {
	const { onChange, name, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask='000'
			definitions={{
				"#": /\d/,
			}}
			inputRef={ref}
			onAccept={(value) => onChange({ target: { name, value } })}
			overwrite
		/>
	);
});
CVCFormat.displayName = "CVCFormat";
CVCFormat.propTypes = {
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
};

const CreditCard = () => {
	const dispatch = useDispatch();
	const [cardData, setCardData] = useState({
		number: "",
		name: "",
		expiry: "",
		cvc: "",
		focus: "",
	});
	const [cardAdd, { isLoading }] = useCardAddMutation();

	const handleInputFocus = (e) => {
		setCardData({
			...cardData,
			focus: e.target.name,
		});
	};

	const handleInputChange = (e) => {
		setCardData({
			...cardData,
			[e.target.name]: e.target.value,
		});
	};

	const handleCancel = () => {
		dispatch(handleModal(false));
	};

	const handleSubmit = () => {
		cardAdd(cardData)
			.then((res) => {
				const { error, data } = res;
				if (error) {
					return dispatch(
						addError({
							kind: "fetch_error",
							severity: "error",
							message: error.data.message,
						}),
					);
				}
				dispatch(
					addError({
						kind: "fetch_success",
						severity: "success",
						message: data.message,
					}),
				);

				const { op, card } = data;
				if (op) {
					dispatch(
						handleCards({
							id: card.id,
							number: card.number,
							name: card.name,
						}),
					);
					dispatch(handleModal(false));
				}
			})
			.catch((err) => {
				console.log("catch -->", err);
				dispatch(
					addError({
						kind: "fetch_error",
						severity: "error",
						message:
							"Hubo un problema al tratar de registrar la tarjeta por favor inténtelo más tarde",
					}),
				);
			});
	};

	return (
		<Grid container justify='center' spacing={2} id='PaymentForm'>
			<Grid item>
				<Cards
					number={cardData.number}
					name={cardData.name}
					expiry={cardData.expiry}
					cvc={cardData.cvc}
					focused={cardData.focus}
					placeholders={{
						name: "Titular",
					}}
				/>
			</Grid>
			<Grid container item sx={{ display: "flex" }}>
				<Box sx={{ flexGrow: 1 }}>
					<Grid item>
						<TextField
							required
							fullWidth
							type='text'
							label='Titular'
							variant='filled'
							name='name'
							value={cardData.name}
							onChange={handleInputChange}
							onFocus={handleInputFocus}
						/>
					</Grid>
					<Grid item>
						<TextField
							required
							fullWidth
							type='tel'
							label='Número de tarjeta'
							variant='filled'
							name='number'
							value={cardData.number}
							onChange={handleInputChange}
							onFocus={handleInputFocus}
							InputProps={{
								inputComponent: FormantCardNumber,
							}}
						/>
					</Grid>
					<Grid item container spacing={1} mt={1}>
						<Grid item>
							<TextField
								required
								fullWidth
								type='text'
								label='Fecha de expiración'
								variant='filled'
								name='expiry'
								value={cardData.expiry}
								onChange={handleInputChange}
								onFocus={handleInputFocus}
								InputProps={{
									inputComponent: ExpiryDate,
								}}
							/>
						</Grid>
						<Grid item>
							<TextField
								required
								fullWidth
								type='text'
								label='Código de seguridad'
								variant='filled'
								name='cvc'
								value={cardData.cvc}
								onChange={handleInputChange}
								onFocus={handleInputFocus}
								InputProps={{
									inputComponent: CVCFormat,
								}}
							/>
						</Grid>
					</Grid>
					<Grid item container mt={2} sx={{ display: "flex" }}>
						<Box sx={{ flexGrow: 1 }}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Button fullWidth variant='contained' onClick={handleCancel}>
										Cancelar
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button
										fullWidth
										variant='contained'
										color='success'
										onClick={handleSubmit}
										disabled={isLoading}
									>
										Guardar
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
};

export default CreditCard;
