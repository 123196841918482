import { useRef, useState } from "react"
import { Box, IconButton, Modal } from "@mui/material"
import { styled } from "@mui/material/styles"
import ButtonBases from "./ButtonBases"
import CancelIcon from "@mui/icons-material/Cancel"
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle"
import CircularProgress from "@mui/material/CircularProgress"
import { compressAccurately } from "image-conversion"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import PropTypes from "prop-types"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "92%",
  height: "95%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  flexGrow: 1,
  p: 1,
}

// TODO: use redux to get the picture
const TakePictureCustom = ({
  name,
  showName,
  cameraWidth,
  cameraHeight,
  handleTakePicture,
}) => {
  const [open, setOpen] = useState(false)
  const [dataUrl, setDataUrl] = useState("")
  const [uploading, setUploading] = useState(false)
  const [file, setFile] = useState(null)
  const [rotation, setRotation] = useState(0)
  const inputEl = useRef(null)
  const rotationOptions = [1, 6, 3, 8]

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const handlePictureSelected = () => {
    setUploading(true)
    setFile(inputEl.current.files[0])
    handleImage(inputEl.current.files[0])
  }

  const handleImage = async (blob, rotation = 1) => {
    // canvas = document.createElement("canvas")
    const res = await compressAccurately(blob, {
      size: 100,
      quality: 0.9,
      type: "image/jpeg",
      orientation: rotation,
      scale: 0.5,
    })
    setUploading(false)
    const reader = new FileReader()
    reader.readAsDataURL(res)
    reader.onload = (event) => {
      const base64 = event.target.result
      handleTakePicture({ name, showName, base64 })
      setDataUrl(base64)
      setUploading(false)
    }
  }

  const onButtonClick = () => {
    inputEl.current.click()
  }

  const Input = styled("input")({
    display: "none",
  })

  const handleRotation = () => {
    setRotation(rotation === 4 ? 1 : rotation + 1)
    const turn = rotation === 4 ? 1 : rotation + 1
    setUploading(true)
    handleImage(file, rotationOptions[turn])
  }

  return (
    <Box>
      <Input
        name={name}
        accept='image/x-png,image/jpeg'
        id='contained-button-file'
        type='file'
        ref={inputEl}
        onChange={handlePictureSelected}
      />
      <ButtonBases
        title={uploading ? "Cargando ..." : showName}
        cameraWidth={cameraWidth}
        cameraHeight={cameraHeight}
        dataUrl={dataUrl}
        handleOpen={handleOpen}
        onButtonClick={onButtonClick}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box>
          <IconButton
            sx={{
              position: "absolute",
              top: "3%",
              right: "0.5%",
              backgroundColor: "ButtonFace",
              zIndex: "tooltip",
            }}
            onClick={handleClose}
          >
            <CancelIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <IconButton
            sx={{
              position: "fixed",
              top: "14%",
              right: "0.5%",
              backgroundColor: "ButtonFace",
              zIndex: "tooltip",
            }}
            onClick={onButtonClick}
          >
            <FileUploadIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <IconButton
            sx={{
              position: "fixed",
              top: "25%",
              right: "0.5%",
              backgroundColor: "ButtonFace",
              zIndex: "tooltip",
            }}
            onClick={handleRotation}
          >
            <ChangeCircleIcon sx={{ fontSize: 40 }} />
          </IconButton>
          <Box sx={style}>
            {!uploading ? (
              <img src={dataUrl} alt='upload' width='100%' />
            ) : (
              <CircularProgress color='primary' size={120} />
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

TakePictureCustom.propTypes = {
  name: PropTypes.string.isRequired,
  showName: PropTypes.string.isRequired,
  cameraWidth: PropTypes.number.isRequired,
  cameraHeight: PropTypes.number.isRequired,
  handleTakePicture: PropTypes.func.isRequired,
}

export default TakePictureCustom
