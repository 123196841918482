import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { BASE_URI } from "../../../constant/uris"

// in this api is use RTK toolkit from redux-toolkit
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URI,
})

// import retry from "@reduxjs/toolkit/query/react" if you want use retry
// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 })

export const rolesApi = createApi({
  reducerPath: "roles",
  baseQuery,
  endpoints: (builder) => ({
    rolesList: builder.query({
      query: () => "roles/list",
    }),
  }),
})

export const { useRolesListQuery } = rolesApi
