import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material"
import { useState } from "react"
import data from "./data.json"
import SelectRegions from "../SelectRegions/SelectRegions"
import { useAddAddressMutation } from "../../redux/apis/addresses"
import { handleNewAddress } from "../../redux/slices/addresses"
import { useDispatch } from "react-redux"
import { handleModal } from "../../redux/slices/helpers"

export const AddressRegister = () => {
  const dispatch = useDispatch()
  const [values, setValues] = useState({})
  const [addAddress, { isLoading }] = useAddAddressMutation()

  const handleChange = (event) => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleRegionChange = () => (event) => {
    const { value } = event.target
    setValues({ ...values, selectedRegion: value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    addAddress(values)
      .then((res) => {
        if (res.error) {
          console.log(res.error)
        }

        const { op, address } = res.data
        if (op) {
          setValues({})
          dispatch(handleNewAddress(address))
          dispatch(handleModal(false))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <h1>Agregar una Dirección</h1>
      <Box component="form" onSubmit={handleSubmit}>
        {data.inputs.map((item) => {
          return (
            <TextField
              key={item.name}
              fullWidth
              name={item.name}
              label={item.label}
              required={item.required}
              type={item.type}
              variant="filled"
              value={values[item.name] || ""}
              onChange={handleChange}
            />
          )
        })}
        <SelectRegions
          region={values.selectedRegion || ""}
          handleChange={handleRegionChange}
        />

        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} md>
            <Button fullWidth variant="contained" color="error">
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12} md>
            <Button fullWidth variant="contained" color="primary" type="submit">
              {!isLoading ? "Guardar" : <CircularProgress />}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
