import AppFrame from "../../components/AppFrame/AppFrame"
import { RentalsList } from "../../components/Rental/RentalsList"

const Dashboard = () => {
  return (
    <AppFrame title='Tablero'>
      <RentalsList />
    </AppFrame>
  )
}

export default Dashboard
