import { Button, Fab, Icon, Modal as MUIModal } from "@mui/material"
import { Box } from "@mui/system"
import { modalStyle } from "../../styles/modal"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { handleModal, selectModal } from "../../redux/slices/helpers"

export const Modal = ({
  title,
  description,
  icon,
  textButton,
  fullWidth = false,
  variant = "text",
  color = "primary",
  children,
}) => {
  const dispatch = useDispatch()
  const open = useSelector(selectModal)
  const handleOpen = () => dispatch(handleModal(true))
  const handleClose = () => dispatch(handleModal(false))

  return (
    <>
      <Button
        startIcon={icon}
        onClick={handleOpen}
        fullWidth={fullWidth}
        variant={variant}
        color={color}
      >
        {textButton}
      </Button>
      <MUIModal
        open={open}
        onClose={handleClose}
        aria-labelledby={title}
        aria-describedby={description}
      >
        <>
          <Fab
            color="error"
            aria-label="close"
            sx={{ position: "absolute", right: 16 }}
            onClick={handleClose}
          >
            <Icon baseClassName="material-icons">close</Icon>
          </Fab>
          <Box sx={modalStyle}>{children}</Box>
        </>
      </MUIModal>
    </>
  )
}

Modal.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  textButton: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.element.isRequired,
}
