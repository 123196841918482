import { Box } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import {
  selectVerifyVehicle,
  setVerifyDocument,
} from "../../redux/slices/vehicles"
import { VehicleCarousel } from "../Vehicles/VehicleCarousel"
import { Button, CircularProgress, Grid } from "@mui/material"
import { TableDocuments } from "./TableDocuments"
import { useNavigate } from "react-router-dom"
import { useVehicleValidateMutation } from "../../redux/apis/vehicles"
import { ViewCheckboxes } from "../Vehicles/ViewCheckBoxes"

export const VehicleVerification = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const vehicle = useSelector(selectVerifyVehicle)
  const [vehicleValidate, { isLoading }] = useVehicleValidateMutation()

  const backToList = () => {
    navigate("/vehicles-revision")
  }

  // TODO: handle error here
  const handleValidate = () => {
    vehicleValidate(vehicle.id).then(({ data, error }) => {
      if (data.op) {
        dispatch(setVerifyDocument(data.vehicle))
        navigate("/vehicles-revision")
      }

      if (error) {
        console.log(error)
      }
    })
  }

  return (
    <Box>
      <Box
        sx={{ height: "65%", width: "65%", position: "relative", left: "18%" }}
      >
        <VehicleCarousel photos={vehicle.photos} />
      </Box>
      <hr />
      <TableDocuments vehicleID={vehicle.id} />
      <hr />
      <ViewCheckboxes properties={vehicle.properties} />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant='outlined'
            color='error'
            onClick={backToList}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant='contained'
            color='success'
            onClick={handleValidate}
            disabled={isLoading}
          >
            {!isLoading ? "Verificar" : <CircularProgress size={24} />}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
