import { useState, useEffect } from "react"
import { Outlet } from "react-router-dom"
import AppFrame from "../../components/AppFrame/AppFrame"
import LoginForm from "../../components/LoginForm/LoginForm"
import { useLocation } from "react-router-dom"

const SignIn = () => {
  const location = useLocation()
  const [isSpecial, setIsSpecial] = useState(false)
  const role = location.pathname.split("/")[2]

  useEffect(() => {
    if (role !== undefined) {
      setIsSpecial(true)
    }
  }, [role])

  return (
    <AppFrame title="Acceder">
      <Outlet role={role} />
      <LoginForm isSpecial={isSpecial} />
    </AppFrame>
  )
}

export default SignIn
