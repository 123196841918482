import { Button, CircularProgress, Icon } from "@mui/material"
import PropTypes from "prop-types"
import { useState } from "react"
import { MyModal } from "./MyModal"

export const ShowDocuments = ({ title, path, hook }) => {
  const { data, isLoading, isSuccess, isError, error } = hook(path)
  const [isOpen, setIsOpen] = useState(false)

  let content
  if (isLoading) {
    content = <CircularProgress size={60} />
  } else if (isError) {
    content = <p>{error.error}</p>
    console.log(error)
  } else if (isSuccess) {
    content = <img src={data} alt={title} width='100%' height='100%' />
  }

  const handleModal = (value) => {
    setIsOpen(value)
  }

  return (
    <div>
      <Button
        startIcon={<Icon baseClassName='material-icons'>description</Icon>}
        onClick={() => handleModal(true)}
        variant='text'
        color='primary'
      >
        Ver
      </Button>
      <MyModal open={isOpen} handleModal={handleModal}>
        <h3>{title}</h3>
        {content}
      </MyModal>
    </div>
  )
}

ShowDocuments.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  hook: PropTypes.func.isRequired,
}
