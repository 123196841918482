import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Icon,
  IconButton,
  Typography,
  styled,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
} from "@mui/material"
import PropTypes from "prop-types"
import { useState } from "react"
import { BASE_URI } from "../../constant/uris"
import { checkboxes } from "../../constant/vehicleData"
import { selectUser } from "../../redux/slices/auth"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setVehicleToRental } from "../../redux/slices/vehicles"

const ExpandMore = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const Vehicles = ({ data }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)
  const user = useSelector(selectUser)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleRentalClick = () => {
    dispatch(setVehicleToRental(data))
    navigate(`rentals/vehicle`)
  }

  return (
    <Box sx={{ maxWidth: 345, m: 2 }}>
      <Card>
        <CardHeader
          title={`${data.properties.brand} ${data.properties.model}`}
          subheader={`${data.properties.year}`}
        />
        <CardMedia
          component="img"
          height="194"
          image={`${BASE_URI}${data.photos.FRONT.img}`}
          alt={`${data.properties.brand} ${data.properties.model}`}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {data.properties.description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          {data.available ? (
            <Button
              aria-label="rentar"
              color="success"
              variant="contained"
              sx={{ mr: 2 }}
              startIcon={<Icon>monetization_on</Icon>}
              onClick={handleRentalClick}
            >
              <Typography>{data.properties.price}</Typography>
            </Button>
          ) : (
            "Vehículo no disponible"
          )}
          <IconButton aria-label="share">
            <Icon>share</Icon>
          </IconButton>
          {user?.id === data.user_id && (
            <IconButton aria-label="edit">
              <Icon>edit</Icon>
            </IconButton>
          )}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <Icon>expand_more</Icon>
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid item container spacing={1} sx={{ mt: 2 }}>
              {checkboxes.map((checkbox) => (
                <Grid item key={checkbox.name}>
                  <FormControlLabel
                    label={checkbox.label}
                    control={
                      <Checkbox
                        name={checkbox.name}
                        checked={data.properties[checkbox.name]}
                        inputProps={{
                          "aria-label": checkbox.label,
                          disabled: true,
                        }}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  )
}

Vehicles.propTypes = {
  data: PropTypes.object,
}
