import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useRegionsListQuery } from "../../redux/apis/information/regions"
import PropTypes from "prop-types"

const SelectRegions = ({ region, handleChange }) => {
  const [selectRegion, setSelectRegion] = useState()
  const [error, setError] = useState("")
  const {
    data: dataRegions,
    isLoading: isLoadingRegions,
    isSuccess: isSuccessRegions,
    isError: isErrorRegions,
  } = useRegionsListQuery()

  useEffect(() => {
    if (isLoadingRegions) {
      setSelectRegion(
        <MenuItem key='empty' disabled value=''>
          <em>Cargando...</em>
        </MenuItem>
      )
    } else if (isSuccessRegions) {
      if (dataRegions.op && dataRegions.regions) {
        setSelectRegion(
          dataRegions.regions.map((region) => (
            <MenuItem key={region.id} value={region.id}>
              {region.city}
            </MenuItem>
          ))
        )
      } else {
        setSelectRegion(
          <MenuItem key='empty' disabled value=''>
            <em>No se encontraron ciudades disponibles</em>
          </MenuItem>
        )
      }
    } else if (isErrorRegions) {
      setError(
        "Error al cargar las ciudades por favor intente de nuevo mas tarde"
      )
    }
  }, [isLoadingRegions, isSuccessRegions, isErrorRegions, dataRegions])

  return (
    <FormControl required variant='filled' fullWidth error={isErrorRegions}>
      <InputLabel id='regions-label'>Ciudad </InputLabel>
      <Select
        labelId='regions-label'
        id='regions'
        label='Ciudad'
        value={region}
        onChange={handleChange("region")}
      >
        {selectRegion}
      </Select>
      {isErrorRegions ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  )
}

SelectRegions.propTypes = {
  region: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
}

export default SelectRegions
