import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Icon,
} from "@mui/material"
import PropTypes from "prop-types"

const ItemCard = ({ card }) => {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          Tarjeta
        </Typography>
        <Typography sx={{ mt: 2 }} variant='body2'>
          {card.number}
        </Typography>
        <Typography sx={{ mt: 2 }} variant='caption'>
          {card.name}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size='small'>Editar</Button>
        <Button size='small' color='error' startIcon={<Icon>delete</Icon>}>
          Eliminar
        </Button>
      </CardActions>
    </Card>
  )
}

ItemCard.propTypes = {
  card: PropTypes.shape({
    name: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }).isRequired,
}

export default ItemCard
