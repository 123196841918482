import { Stack } from "@mui/material"
import ItemCard from "./ItemCard"
import { useCardGetByUserQuery } from "../../redux/apis/credit_cards"
import CircularProgress from "@mui/material/CircularProgress"
import { useDispatch, useSelector } from "react-redux"
import { addError } from "../../redux/slices/errors"
import { useEffect } from "react"
import { selectCards } from "../../redux/slices/credit_cards"

const ListCards = () => {
  const dispatch = useDispatch()
  const { data, isLoading, isSuccess, isError, error } = useCardGetByUserQuery()
  const cards = useSelector(selectCards)

  useEffect(() => {
    if (isError) {
      dispatch(
        addError({
          kind: "fetch_error",
          severity: "error",
          message: error.data.message,
        })
      )
    }
  }, [error, isError])

  let content

  if (isLoading) {
    content = <CircularProgress size={60} />
  } else if (isSuccess && data.op) {
    content = cards.map((card) => <ItemCard key={card.id} card={card} />)
  }

  return (
    <Stack direction='row' spacing={2}>
      {content}
    </Stack>
  )
}

export default ListCards
