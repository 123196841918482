import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useGetRoleQuery } from "../../redux/apis/auth"
import { setAuthValues } from "../../redux/slices/auth"
import { useDispatch } from "react-redux"
import { addError } from "../../redux/slices/errors"

const SpecialUsers = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const role = location.pathname.split("/")[2]
  const [status, setStatus] = useState(null)
  const { data, isLoading, isSuccess, isError, error } = useGetRoleQuery(role)

  useEffect(() => {
    if (isLoading) setStatus("obteniendo rol ...")
    if (isSuccess) {
      dispatch(setAuthValues({ role_id: data.role_id }))
      setStatus("rol obtenido")
    }
    if (isError) {
      dispatch(
        addError({
          kind: "FETCH_ERROR",
          severity: "error",
          message: error.data,
        })
      )
    }
  }, [isLoading, isSuccess, isError])

  return <h3>{`${role.charAt(0).toUpperCase()}${role.slice(1)} ${status}`}</h3>
}

export default SpecialUsers
